.footer {
	background-color: $black;
	border-top: 5px solid $main-color;
	color: $gray-light;
}

.footer__block {
	float: left;
	width: percentage(1/4);
	margin: 30px 0;
	@include breakpoint(below-small) {
		display: none;
	}
	.heading {
		margin-bottom: 20px;
		span {
			border-bottom: 3px solid $orange;
			display: inline-block;
			padding: 0 0 8px 0;
			font-size: 1.15em;
			color: $white-light;
			letter-spacing: 0.08em;
			font-weight: normal;
		}
	}
	.link {
		font-size: .95em;
		padding: 8px 0;
		a {
			&:hover {
				color: $orange;
			}
		}
	}
	.tag {
		display: inline-block;
		a {
			display: inline-block;
			background-color: $gray-light;
			color: $black;
			padding: 5px 10px;
			margin: 0 5px 10px 0;
		}
	}
	.iso-img {
		display: inline-block;
		width: 37px;
		padding-right: 10px;
		vertical-align: middle;
	}
}

.footer__language {
	display: none;
	@include breakpoint(below-small) {
		display: block;
		padding: 15px 0;
	}
	.language-list {
		text-align: center;
		li {
			margin: 0 5px;
			@include circle-style-s;
		}
	}
}

.footer__copyright {
	background-color: $orange;
	color: #fff;
	font-size: .85em;
	line-height: 1.3em;
	padding: 10px 0;
	@include breakpoint(below-small) {
		padding: 10px 0;
		font-size: .8em;
	}
}
