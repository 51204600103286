//index carousel with title
.carousel-prev,
.carousel-next {
	position: absolute;
	top: 50%;
	z-index: 99;
	font-size: 40px;
	background-color: rgba(black, 0);
	height: 100px;
	margin-top: -50px;
	width: 50px;
	text-align: center;
	line-height: 100px;
	color: #fff;
	cursor: pointer;
	transition: background-color .5s;
	@include breakpoint(above-desktop) {
		&:hover {
			background-color: rgba(black, .4);
		}
	}
	@include breakpoint(below-small) {
		background: transparent;
		font-size: 30px;
		width: 40px;
	}
}

.carousel-prev {
	left: 0;
}

.carousel-next {
	right: 0;
}

.bg-carousel {
	position: relative;
	height: 750px;
	max-height: calc(100vh - 85px);
	@include breakpoint(below-desktop) {
		height: 450px;
	}
	@include breakpoint(below-small) {
		height: 300px;
	}
	@include breakpoint(below-mobile) {
		height: 235px;
	}
	&__element {
		@extend %bg-cover;
		background-color: #f2f2f2;
		width: 100%;
	}
	a {
		display: block;
		height: 100%;
	}
}

.two-block-slider {
	height: 400px;
	@include breakpoint(below-small) {
		height: 300px;
	}
	&__elememt {
		height: 100%;
		@include clfix;
		position: relative;
	}
	&__image,
	&__text {
		width: 50%;
		float: left;
		height: 100%;
		@include breakpoint(below-small) {
			width: 100%;
			float: none;
		}
	}
	&__image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		@include breakpoint(below-small) {
			z-index: -1;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
		}
	}
	&__text {
		background-color: $main-color;
		padding: 60px;
		@include breakpoint(below-small) {
			padding: 40px;
			background-color: rgba(0,0,0,.5);
		}
	}
	.heading {
		font-size: 3.8em;
		line-height: 1em;
		letter-spacing: 2px;
		font-weight: $weight-bb;
		text-transform: uppercase;
		color: $yellow;
		hyphens: auto;
		@include breakpoint(below-medium) {
			font-size: 2.5em;
		}
		@include breakpoint(below-small) {
			font-size: 2em;
		}
	}
	.sub-heading {
		color: #fff;
		font-size: 1.5em;
		line-height: 1.3em;
		margin-top: 30px;
		font-weight: normal;
		letter-spacing: 1px;
		@include breakpoint(below-medium) {
			font-size: 1.2em;
		}
		@include breakpoint(below-small) {
			margin-top: 20px;
		}
		@include breakpoint(below-mobile) {
			margin-top: 15px;
			font-size: 1em;
		}
	}
}

.post-carousel__item {
	padding: 0 15px;
	outline: none;
}

.post-carousel__link {
	display: block;
}

.post-carousel__tag {
	position: relative;
	display: inline-block;
	padding: 8px 10px;
	color: white;
	background-color: $gray-light;
	z-index: 5;
}

.post-carousel__img {
	display: block;
	transform: translateY(-18px);
}

.post-carousel__heading {
	font-size: 18px;
}

.post-carousel__btn {
	z-index: 20;
	position: absolute;
	top: calc(50% - 20px);
	&.btn--prev {
		left: -40px;
		@include breakpoint(below-small) {
			left: 0;
		}
	}
	&.btn--next {
		right: -40px;
		@include breakpoint(below-small) {
			right: 0;
		}
	}
}

.post-carousel__icon {
	font-size: 40px;
}
