.two-blocks-01 {
	@include clfix;
	&__item {
		background-color: #fff;
		margin-bottom: gutter(20);
		height: 280px;
		overflow: hidden;
		font-size: .85em;
		position: relative;

		@include clfix;
		@include span(10 of 20);
		&:nth-child(2n) {
			@include span(10 of 20 last);
		}
		&:nth-child(4n-1),
		&:nth-child(4n) {
			.two-blocks-01__image,
			.two-blocks-01__text {
				float: right;
			}
		}
		@include breakpoint(below-desktop) {
			font-size: 1em;
			@include span(12);
			&:nth-child(2n) {
				@include span(12);
			}
		}
		@include breakpoint(below-small) {
			height: 200px;
			margin: 0;
			&:nth-child(4n-1),
			&:nth-child(4n) {
				.two-blocks-01__image,
				.two-blocks-01__text {
					float: left;
				}
			}
			&:nth-child(2n) {
				.two-blocks-01__image,
				.two-blocks-01__text {
					float: right;
				}
			}
		}
		@include breakpoint(above-desktop) {
			&:hover {
				.heading {
					color: $main-color;
				}
			}
		}
		> a {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			text-indent: -999px;
			&:after {
				content: "";
				position: absolute;
				background-color: $main-color;
				height: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transition: height .3s;
			}
			@include breakpoint(above-desktop) {
				&:hover {
					&:after {
						height: 12px;
					}
				}
			}
		}
	}
	&__image,
	&__text {
		float: left;
		width: 50%;
		height: 100%;
		padding: $content-padding;
		@include breakpoint(below-mobile) {
			padding: 15px;
		}
		img {
			@extend %full-img;
		}
	}
	&__image {
		@extend %bg-cover;
	}
	.heading {
		@extend %news-list-heading;
		@include breakpoint(below-mobile) {
			font-size: 1em;
		}
	}
	.description {
		@extend %news-list-description;
	}
	.date, .author, .type {
		@extend %gray-style;
		@include breakpoint(below-small) {
			font-size: .9em;
		}
	}
	.author {
		margin-bottom: 5px;
		@extend %ellipsis;
	}
}
