$table-line-color: #aaa;

/* event table */
.table-1 {
	width: 100%;
	margin-bottom: 30px;
	tr:first-child, {
		td, th {
			padding-top: 20px;
			@include breakpoint(below-mobile) {
				padding-top: 15px;
			}
		}
	}
	tr:last-child {
		td,th {
			padding-bottom: 20px;
			@include breakpoint(below-mobile) {
				padding-bottom: 15px;
			}
		}
	}
	th, td {
		padding: 10px 20px;
		background-color: #f2f2f2;
		line-height: 1.3em;
		@include breakpoint(below-mobile) {
			padding: 5px 10px;
		}
	}
	th {
		text-align: left;
		width: 110px;
		font-weight: 600;
		padding-right: 0px;
		@include breakpoint(below-mobile) {
			width: 50px;
		}
	}
}


/* product table style */

.product-table {
	padding: 40px 0;
}

.product-table__heading {
	margin: 0 auto;
	margin-bottom: 20px;
	max-width: 900px;
	&.orange {
		color: $orange;
	}
}

.porduct-list {
	max-width: 900px;
	margin: 0 auto;
	text-align: center;
	border: 1px solid $table-line-color;
	overflow: hidden;
	[class*="col"] {
		padding: 20px 8px;
		display: table-cell;
		vertical-align: middle;
		line-height: 1.3em;
		@include breakpoint(below-mobile) {
			padding: 10px 6px;
		}
	}
	.col-1 {
		width: 20%;
		border-right: 1px solid $table-line-color;
		@include breakpoint(below-small) {
			width: 30%;
		}
	}
	.col-2 {
		width: 50%;
		border-right: 1px solid $table-line-color;
		@include breakpoint(below-small) {
			width: 40%;
		}
		@include breakpoint(below-mobile) {
			width: 30%;
		}
	}
	.col-3 {
		width: 30%;
		@include breakpoint(below-mobile) {
			width: 40%;
		}
	}
	&__heading {
		background-color: $black;
		color: #fff;
		display: table;
		table-layout: fixed;
		width: 100%;
		letter-spacing: 0.04em;
		font-size: 1.2em;
		font-weight: normal;
		@include breakpoint(below-mobile) {
			font-size: .95em;
		}
	}
	&__product {
		display: table;
		table-layout: fixed;
		width: 100%;
		position: relative;
		overflow: hidden;
		transition: background-color .2s;
		&:nth-child(odd) {
			background-color: $white-light;
		}
		&:hover {
			background-color: $white-light - 20;
			.product-name {
				background-color: $orange - 20;
			}
		}
		@include breakpoint(below-mobile) {
			font-size: 0.95em;
		}
	}
	&__app {
		.col-1 {
			width: 20%;
			@include breakpoint(below-small) {
				width: 30%;
			}
		}
		.col-2 {
			width: 80%;
			border-right: 0;
			text-align: left;
			padding: 20px;
			@include breakpoint(below-small) {
				width: 70%;
			}

		}
	}
	.product-name {
		background-color: $orange;
		color: #fff;
		transition: background-color .2s;
	}
	.name {
		color: #fff;
		font-size: 1.1em;
		word-break: break-word;
		@include breakpoint(below-mobile) {
			font-size: 1em;
		}
	}
}

[data-lang="jp"] {
	.product-name .name {
		font-size: 0.95em;
	}
}

.product-main {
	max-width: 900px;
	margin: 0 auto;
	margin-top: 20px;
	&__element {
		margin-bottom: 5px;
		display: table;
		table-layout: fixed;
		width: 100%;
		@include breakpoint(below-mobile) {
			display: block;
			margin-bottom: 20px;
		}
	}
	[class*="col"] {
		display: table-cell;
		vertical-align: middle;
		line-height: 1.5em;
		@include breakpoint(below-mobile) {
			width: 100%;
			display: block;
		}
		> p {
			padding: 10px 15px;
		}
	}
	.col-1 {
		width: 40%;
		border-right: 5px solid #fff;
		background-color: $black;
		p {
			color: #fff;
		}
		@include breakpoint(below-small) {
			width: 25%;
		}
		@include breakpoint(below-mobile) {
			border-right: 0;
			width: 100%;
		}
	}
	.col-2 {
		width: 60%;
		background-color: $white-light - 5;
		position: relative;
		p {
			color: $black;
		}
		@include breakpoint(below-small) {
			width: 75%;
		}
		@include breakpoint(below-mobile) {
			width: 100%;
		}
	}
	.product-image {
		display: block;
		width: auto;
		height: auto;
		max-width: 100%;
		mix-blend-mode: multiply;
		margin: 0 auto;
	}
	.product-contact-us {
		@include bg-hover($orange, $orange - 20);
		line-height: 45px;
		width: 100%;
		color: #fff;
		text-align: center;
		@include breakpoint(below-mobile) {

		}
	}
	.structure-txt {
		display: inline-block;
		margin-top: 20px;
		font-size: .8em;
		line-height: 1.2em;
		color: $gray;
	}
}
