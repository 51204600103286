.breadcrumb {
	margin-bottom: 22px;
	li {
		display: inline-block;
		font-size: .9em;
		line-height: 1.3em;
		&:last-child {
			color: $red;
		}
		a:hover {
			color: $gray-light;
		}
	}
}

.catalogue,
.product {
	.breadcrumb {
		li:nth-child(2),
		li:nth-child(3) {
			pointer-events: none;
		}
	}
}

.article-single {
	.B_crumbBox {
		li:last-child {
			display: none;
		}
	}
}