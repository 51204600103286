//------------------------------------
//  MIXINS
//------------------------------------

@mixin breakpoint($point) {
	@if $point == 'above-large' {
		@media (min-width: 1401px) { @content; }
	}
	@if $point == 'below-large' {
		@media (max-width: 1400px) { @content; }
	}
	@else if $point == 'above-medium' {
		@media (min-width: 1251px) { @content; }
	}
	@else if $point == 'below-medium' {
		@media (max-width: 1250px) { @content; }
	}
	@else if $point == 'above-desktop' {
		@media (min-width: 1025px)  { @content; }
	}
	@else if $point == 'below-desktop' {
		@media (max-width: 1024px)  { @content; }
	}
	@else if $point == 'above-small' {
		@media (min-width: 851px)  { @content; }
	}
	@else if $point == 'below-small' {
		@media (max-width: 850px)  { @content; }
	}
	@else if $point == 'above-tablet' {
		@media (min-width: 768px)  { @content; }
	}
	@else if $point == 'below-tablet' {
		@media (max-width: 767px)  { @content; }
	}
	@else if $point == 'below-mobile' {
		@media (max-width: 550px)  { @content; }
	}
	@else if $point == 'below-i6' {
		@media (max-width: 370px)  { @content; }
	}
}

@mixin bg-hover-darken($baseColor, $percent) {
	background-color: $baseColor;
	transition:  background-color .25s;
	@include breakpoint(above-desktop) {
		&:hover {
			background-color: $baseColor - $percent;
		}
	}
}

@mixin bg-hover($baseColor, $hoverColor) {
	background-color: $baseColor;
	transition:  background-color .25s;
	@include breakpoint(above-desktop) {
		&:hover {
			background-color: $hoverColor;
		}
	}
}

@mixin bg-color-hover($baseColor, $hoverColor, $color) {
	background-color: $baseColor;
	transition:  background-color .25s, color .25s;
	@include breakpoint(above-desktop) {
		&:hover {
			color: $color;
			background-color: $hoverColor;
		}
	}
}


@mixin color-hover-darken($baseColor, $percent) {
	color: $baseColor;
	transition:  color .25s;
	@include breakpoint(above-desktop) {
		&:hover {
			color: darken($baseColor, $percent);
		}
	}
}

@mixin ellipsis($line, $height) {
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical;
	overflow: hidden;
	height: $height;	//$height must be $line times of 'line-height'
}

%ellipsis {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin clfix {
	&:before, &:after {
    content: " ";
    display: table;
	}
	&:after {
    clear: both;
	}
}

%full-img {
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;
}

%bg-cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

@mixin circle-style {
	background-color: #4e4e4e;
	color: #ccc;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 100%;
	display: inline-block;
	line-height: 42px;
	font-size: 1em;
	text-align: center;
	cursor: pointer;
	transition: background-color .25s, color .25s;
	&:hover, &.active {
		background-color: $orange;
		color: #fff;
	}
}

@mixin circle-style-s {
	background-color: #4e4e4e;
	color: #ccc;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	display: inline-block;
	line-height: 32px;
	text-align: center;
	font-size: .85em;
	transition: background-color .25s, color .25s;
	&:hover, &.active {
		background-color: $orange;
		color: #fff;
	}
}
