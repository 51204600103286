.search-page {
	.page-content {
		.search-input {
			input[type="text"] {
				background-color: $white-light;
				color: $gray-dark;
			}
		}

	}
}

.search-blcok {
	padding: $content-padding 0;
	position: relative;
	.heading,
	.three-search-bar {
		display: inline-block;
		vertical-align: middle;
	}
	.heading {
		color: $main-color;
		margin-right: 10px;
		font-size: 1.35em;
		width: 150px;
		@include breakpoint(below-desktop) {
			display: block;
			margin-bottom: 10px;
		}
		@include breakpoint(below-small) {
			margin-bottom: 0;
		}
	}
	.three-search-bar {
		width: 765px;
		@include breakpoint(below-desktop) {
			width: 100%;
		}
		.dropdown {
			margin-right: 15px;
			@include breakpoint(above-small) {
				&:last-child {
					margin-right: 0;
				}
			}
			@include breakpoint(below-small) {
				max-width: 23%;
				margin-top: 10px;
			}
			@include breakpoint(below-mobile) {
				max-width: 250px;
				display: block;
				margin: 10px auto;
			}
		}
	}
	#filter-button {
		position: absolute;
		right: 0;
		top: 18px;
		@include breakpoint(below-desktop) {
			position: static;
			margin: 20px 0 0 0;
		}
		@include breakpoint(below-mobile) {
			margin: 10px auto 0 auto;
		}
	}
}

.search-result {
	margin-top: 20px;
	@include breakpoint(above-desktop) {
		min-height: 40vh;
	}
	@include breakpoint(below-desktop) {
		min-height: 60vh;
	}
}

.search-no-result {
	margin-top: 20px;
	min-height: 400px;
	@include breakpoint(above-small) {
		min-height: 40vh;
	}
}

#filter-result,
#search-result  {
	@include clfix;
}

.filter-result__item,
.search-result__item {
	height: 230px;
	line-height: 230px;
	overflow: hidden;
	text-align: center;
	margin-bottom: gutter(12);
	border: 1px solid #ccc;
	transition: border-color .4s;
	background-color: $white-light;
	@include breakpoint(above-small) {
		@include span(3);
		&:nth-child(4n) {
			@include span(3 last);
		}
	}
	@include breakpoint(below-small) {
		@include span(4);
		&:nth-child(3n) {
			@include span(4 last);
		}
		height: 100px;
	}
	@include breakpoint(below-mobile) {
		@include span(6);
		&:nth-child(3n) {
			@include span(6);
		}
		&:nth-child(2n) {
			@include span(6 last);
		}
	}
	@include breakpoint(above-desktop) {
		background-size: 200% 100%;
		background-image: linear-gradient(to right, transparent 50%, #fff 50%);
		transition: background-position .3s, cubic-bezier(0.19, 1, 0.22, 1) .3s, background-color .5s ease;
		&:hover {
			background-color: #fff;
			background-position: -100% 100%;
			border-color: $orange;
			.heading {
				color: $main-color;
			}
		}
	}
	> a {
		display: block;
		@include breakpoint(below-small) {
			display: flex;
			height: 100%;
			justify-content: center;
			align-items: center;
		}
	}
	.heading {
		line-height: 1.3em;
		display: inline-block;
		font-size: 1.3em;
		color: #777;
		transition: color .3s;
		margin-right: 0;
		padding: 20px;
		@include breakpoint(below-small) {
			font-size: 1.1em;
			line-height: 1.3em;
		}
		@include breakpoint(below-mobile) {
			font-size: 1em;
		}
	}
}

#filter-no-result,
.search-no-result {
	width: 100%;
	height: 100%;
	color: $gray;
	font-size: 1.1em;
	text-align: center;
	background-color: $white-light;
	min-height: 40vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}

#filter-result-loader {
	width: 30px;
	height: 30px;
	background: url('../img/common/loader.gif') 0 0 no-repeat;
	margin: 0 auto;
}
