//------------------------------------
//  Width and Height
//------------------------------------

//wrap
$inner-wrap: 1140px;
$md-wrap: 940px;
$sm-wrap: 800px;

//nav
$nav-l-height: 85px;
$nav-l-height--small: 65px;
$nav-s-height: 50px;

//block
$block-padding: 60px;
$content-padding: 22px;
$content-padding-s: 12px;


//------------------------------------
//  FONT FAMILY
//------------------------------------

$base-font: "Helvetica", "Arial", "Microsoft JhengHei", "微軟正黑體",  sans-serif;
$simplified-chinese-font: "Helvetica", "Arial", "Microsoft JhengHei", "微软正黑体", "Microsoft YaHei", "微软雅黑体", sans-serif;
$english-font: "Helvetica", "Arial", sans-serif;
$japanese-font: "Helvetica", "Arial", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

$weight-l: 300;
$weight-b: bold;
$weight-bb: bold;

//------------------------------------
//  COLORS
//------------------------------------

$white-light: #f5f5f5;

$gray: #6c6e70;
$gray-light: #C4C4C4;
$gray-dark:	#565756;

$black: #1d1d1b;

$orange: #ff7e27;
$orange-light: #FFDEC8;
$yellow: #FFDD00;
$red: #b12c33;

$main-color: $orange;
$color-sub: $gray;
$blue: #1d70b7;

$dark-line-color: #343434;

$warning: #DA4E4E;
