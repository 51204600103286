@include breakpoint(above-small) {
	%nav-dropdown-wrap {
		opacity: 0;
		pointer-events: none;
		transition: visibility 0s;
		transition-delay: .2s;
		position: fixed;
		top: $nav-l-height;
		left: 0;
		width: 100%;
		height: auto;
		background-color: $black;
		border-top: 3px solid $orange;
	}

	%nav-dropdown-wrap-active {
		opacity: 1;
		pointer-events: auto;
	}

	.nav {
		width: 100%;
		height: $nav-l-height;
		background-color: $black;
		position: fixed;
		z-index: 998;
		transition: height .3s;
		@include clfix;
		.container {
			height: 100%;
		}
	}

	.nav--small {
		height: $nav-l-height--small;
		.search-btn,
		.nav__language {
			transform: translateY(-14px);
		}
		.level-menu__level-2--wrap,
		.nav__dropdown {
			top: $nav-l-height--small;
		}
		.nav__level-menu {
			.level-1 {
				line-height: $nav-l-height--small;
			}
		}
		.nav__logo {
			background-size: 80px auto;
			background-position: 0 5px;
		}
	}

	.nav__logo {
		h1,h2 {
			text-indent: -999px;
		}
		float: left;
		height: 100%;
		width: 200px;
		background: url('../img/common/logo-white.svg') 0 9px no-repeat;
		background-size: 100px auto;
		transition: background-size .3s;
		a {
			display: block;
			width: 120px;
			height: 100%;
		}
	}

	.nav__level-menu {
		float: left;
		height: 100%;
	}

	.nav__other-link {
		float: right;
		height: 100%;
		padding-top: 26px;
		transition: transform .3s;
	}

	.nav__level-menu {
		.level-1 {
			display: block;
			padding: 0 20px;
			cursor: pointer;
			transition: color .25s, line-height .3s;
			height: 100%;
			line-height: $nav-l-height;
			-webkit-transform: translateZ(0);
			&:hover {
				color: $orange;
			}
		}
		.heading {
			height: 55px;
			font-size: 1.1em;
		}
		.container {
			min-height: 410px;
		}
		.back-heading {
			display: none;
		}
	}

	.level-menu__level-1 {
		height: 100%;
		color: #eee;
		> li {
			font-size: .95em;
			float: left;
			height: 100%;
		}
	}

	.level-menu__level-2--wrap {
		@extend %nav-dropdown-wrap;
	}

	.level-menu__level-2--wrap {
		.level-menu-prev,
		.level-menu-next {
			position: absolute;
			bottom: 0;
			width: 30px;
			height: 30px;
			border: 1px solid #888;
			color: #aaa;
			text-align: center;
			line-height: 26px;
			cursor: pointer;
			z-index: 99;
			@include bg-hover($black, $black + 20);
			margin-bottom: 20px;
		}
		.level-menu-prev {
			right: 50px;
		}
		.level-menu-next {
			right: 0;
		}
		.slick-disabled {
			opacity: .4;
			pointer-events: none;
		}
	}

	.level-menu__level-3 {
		> div > div {
			border-bottom: 1px solid $dark-line-color;
			> a {
				display: block;
				width: 100%;
				padding: 8px 0;
				line-height: 1.3em;
				&:hover {
					color: $orange;
				}
			}
		}
	}

	.level-menu__item {
		float: left;
		width: percentage(1/5);
		margin: 30px 0;
		padding: 0 12px;
		&:focus {
			outline: 0;
		}
	}

	.level-1-has-sub {
		&:hover {
			.level-menu__level-2--wrap {
				@extend %nav-dropdown-wrap-active;
			}
		}
	}

	.nav__language {
		display: inline-block;
		vertical-align: top;
		margin-right: 10px;
		position: relative;
		.current {
			@include circle-style;
			position: relative;
			z-index: 10;
		}
		.language-list {
			li {
				position: absolute;
				left: 0;
				top: 0;
				visibility: hidden;
				transition: transform .35s;
				transform: translate(5px, 5px);
				&.active {
					visibility: visible;
					&:first-child {
						transform: translate(-40px, -15px);
					}
					&:nth-child(2) {
						transform: translate(-45px, 23px);
					}
					&:nth-child(3) {
						transform: translate(-25px, 55px);
					}
					&:nth-child(4) {
						transform: translate(10px, 65px);
					}
					&:nth-child(5) {
						transform: translate(45px, 55px);
					}
				}
				> a {
					@include circle-style-s;
				}
			}
		}
	}

	.nav__search {
		display: inline-block;
		vertical-align: top;
		.search-btn {
			@include circle-style;
			&.active {
				background-color: $red;
			}
			i {
				position: relative;
				top: -3px;
			}
		}
		.search-input {
			margin: 150px 0;
		}
	}

	.nav__burger-icon,
	.nav__mobile-home {
		display: none;
	}

	.nav__search-small {
		display: none;
	}

} //end above desktop

.nav__dropdown {
	@extend %nav-dropdown-wrap;
	background-color: rgba($black, 0.9);
	&.active {
		@extend %nav-dropdown-wrap-active;
	}
}

#online-booking {
	display: none;
}

[data-lang="zh"] {
	#online-booking {
		display: block;
	}
}
