$placeholder-color: #888;

//paceholder color
::-webkit-input-placeholder {
   color: $placeholder-color;
}
:-moz-placeholder {
   color: $placeholder-color;  
}
::-moz-placeholder {
   color: $placeholder-color;  
}


//text input
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
	width: 100%;
	height: 48px;
	border: 1px solid #ddd;
	text-align: left;
	line-height: 1em;
	color: $black;
	padding: 0 10px;
	padding-top: 4px;
	background-color: $white-light;

	&:focus {
		border-color: #999;
	}
	&.warning {
		border-color: $warning;
	}
}

input[type="submit"] {
	cursor: pointer;
	&.disabled,
	&:disabled {
		pointer-events: none;
		opacity: .5;
	}
}

button.disabled,
button:disabled {
	pointer-events: none;
	opacity: .5;
}

input[type="radio"] {
	display: inline-block;
	vertical-align: top;
	margin: 0 5px 0 0;
	padding: 0;
}

label {
	display: inline-block;
	margin-bottom: 8px;
}

textarea {
	border: 1px solid #ddd;
	background-color: $white-light;
	width: 100%;
	max-width: 100%;
	min-height: 150px;
	padding: 15px 10px;
	color: $black;
	&:focus {
		border-color: #999;
	}
	&.warning {
		border-color: $warning;
	}
}

.search-input {
	position: relative;
	input[type="text"] {
		border: 0;
		background-color: $white-light;
		color: $gray-dark;
		height: 60px;
		line-height: 60px;
		@include breakpoint(below-small) {
			background-color: #333;
			color: $gray-light;
			height: 40px;
			line-height: 40px;
		}
	}
	input[type="submit"] {
		position: absolute;
		width: 100px;
		text-align: center;
		color: #fff;
		top: 0;
		right: 0;
		bottom: 0;
		@include bg-hover-darken($red, 30);
		background-image: url("../img/icons/search.svg");
		background-size: 30px;
		background-position: 45% 45%;
		background-repeat: no-repeat;
		@include breakpoint(below-small) {
			width: 60px;
			color: #fff;
			background-size: 35% 35%;
		}
	}	
}

.disabled {
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	.dropdown {
		border: 2px solid #eee;
		color: #eee;
		pointer-events: none;
	}
}
