/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

*,*::before,*::after { box-sizing: border-box; }

div, nav {
    backface-visibility: hidden;
}

//input reset
button,
[type="text"],
[type="tel"],
[type="email"],
[type="url"],
[type="submit"],
textarea {
	outline: 0;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    border-radius: 0;
    color: inherit;
    -webkit-appearance: none;
}

select {
	border: 0;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	background: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
}

button {
	cursor: pointer;
}

a,
input:focus,
textarea:focus,
select:focus {
    outline: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill{
    -webkit-box-shadow: 0 0 0px 1000px #eee inset;
}
