.article {
	background-color: $white-light;
}

.three-cols {
	@include clfix;
	margin-top: $content-padding;
	@include breakpoint(below-small) {
		margin-top: $content-padding-s;
	}
	&__item {
		background-color: #fff;
		margin-bottom: gutter(12);
		font-size: .85em;
		overflow: hidden;
		position: relative;
		padding: $content-padding;
		@include breakpoint(above-small) {
			@include span(4);
			&:nth-child(3n) {
				@include span(4 last);
			}
		}
		@include breakpoint(below-small) {
			@include span(6);
			&:nth-child(2n) {
				@include span(6 last);
			}
			padding: $content-padding-s;
			font-size: .75em;
		}
		> a {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			text-indent: -999px;
			&:after {
				content: "";
				position: absolute;
				background-color: $main-color;
				height: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transition: height .3s;
			}
			@include breakpoint(above-desktop) {
				&:hover {
					&:after {
						height: 12px;
					}
				}
			}
		}
		@include breakpoint(above-desktop) {
			&:hover {
				.heading {
					color: $main-color;
				}
			}
		}
	}

	&__image {
		margin-bottom: 15px;
	}
	.heading {
		@extend %news-list-heading;
	}
	.description {
		@extend %news-list-description;
	}
	.date {
		@extend %gray-style;
		@include breakpoint(below-small) {
			font-size: .9em;
		}
	}
	.read-more {
		text-transform: uppercase;
		border: 1px solid $orange;
		color: $orange;
		padding: 5px 10px;
		margin-bottom: 5px;
		display: inline-block;
		@include breakpoint(below-small) {
			display: none;
		}
	}
}