//dropdown default
.dropdown {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	max-width: 240px;
	height: 40px;
	background-color: #fff;
	text-align: left;
	position: relative;
	&:before {
		content: "";
		display: block;
		position: absolute;
		width: 10px;
		height: 10px;
		right: 15px;
		top: 50%;
		margin-top: -5px;
		background: url('../img/common/dropdown.svg') 0 0 no-repeat;
	}
	&__text {
		display: block;
		padding-left: 10px;
		vertical-align: middle;
		line-height: 40px;
		@extend %ellipsis;
		width: 90%;
	}
	&__list {
		position: absolute;
		left: 0;
		top: 40px;
		background-color: #f2f2f2;
		box-shadow: 0 5px 15px rgba(0,0,0,0.2);
		width: 100%;
		padding: 10px 0;
		z-index: 999;
		opacity: 0;
		pointer-events: none;
		transition: opacity .15s;
		max-height: 50vh;
		overflow-y: auto; 
		@include breakpoint(below-small) {
			max-height: 300px;
			overflow-y: auto; 
		}
		> li {
			cursor: pointer;
			padding: 12px;
			position: relative;
			line-height: 1.2em;
			&:after {
				teansition: opacity 2s;
				opacity: 0;
				position: absolute;
				top: 50%;
				right: 10px;
				content: "";
				display: block;
				width: 12px;
				height: 12px;
				background: url('../img/common/check.svg') 0 0 no-repeat;
				margin-top: -5px;
			}
			&:hover {
				background-color: #e0e0e0;
				transition: background .25s;
				&:after {
					opacity: 1;
				}
			}
		}
	}
	&.active {
		.dropdown__list {
			pointer-events: auto;
			opacity: 1;
		}
	}

	&.disabled {
		border: 2px solid #eee;
		color: #eee;
		pointer-events: none;
	}
	// &.wrong {
	// 	background-color: red;
	// }
	// 
	&.dropdown-gray {
		background-color: #ddd;
	}
}

[data-value="hide"] {
	display: none !important;
}
