.page-banner {
	height: 450px;
	@include breakpoint(below-small) {
		height: 300px;
	}
	@include breakpoint(below-mobile) {
		height: 200px;
	}
	.image {
		display: table;
		width: 100%;
		height: 100%;
		@extend %bg-cover;
		> div {
			height: 100%;
			display: table-cell;
			text-align: center;
			vertical-align: middle;
			color: #fff;
			background-color: rgba(0,0,0,.1);
			.page-heading {
				line-height: 100%;
				font-size: 2.8em;
				text-shadow: 0 2px 3px rgba(0,0,0,.5);
				@include breakpoint(below-small) {
					font-size: 2.3em;
				}
				@include breakpoint(below-small) {
					font-size: 1.8em;
				}
			}
		}
	}
}
