.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;
	position: fixed;
	width: 100%;
	height: 100vh;
	&.is-active {
		opacity: 1;
		visibility: visible;
	}
	background-color: rgba(0,0,0,0.6);
	top: 0;
	left: 0;
	z-index: 1000;
	&.is-active {
		.modal__container {
			transform: translateY(0);
		}
	}
}

.modal__heading {
	margin-bottom: 5px;
}

.modal__container {
	position: relative;
	width: 560px;
	text-align: center;
	transform: translateY(20px);
	transition: transform 0.3s;
	padding: 40px 20px;
	background-color: white;
	max-height: 80vh;
    overflow-y: auto;
    @include breakpoint(below-tablet) {
		padding: 80px 20px 20px;
	}
}

.modal__close {
	position: absolute;
	padding: 20px;
	color: white;
	background-color: $orange;
	top: 0;
	right: 0;
}

.modal__close-icon {
	font-size: 20px;
	transition: color 0.3s;
}

.modal-form__submit {
	margin: 0 auto;
}

.modal-form {
	margin-top: 40px;
}

.form-sent {
	.btn-line-orange {
		@extend %align-center;
	}
}

.sending-cover {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: rgba(white, 0.8);
	color: $gray-dark;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s;
	&.is-active {
		opacity: 1;
		visibility: visible;
	}
}

