.add-top-gap {
	padding-top: $nav-l-height;
	@include breakpoint(below-small) {
		padding-top: $nav-s-height;
	}
}

.page-content {
	padding: 30px 0 40px 0;
	@include breakpoint(below-small) {
		padding: 22px 0;
	}
}