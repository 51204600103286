.large-search-block {
	padding: $block-padding 0;
	background-color: $main-color;
	background-image: url('../img/science-bg.svg');
	background-repeat: no-repeat;
	background-size: cover;

	.three-search-block {
		text-align: center;
		margin-bottom: 40px;
		> li {
			display: inline-block;
			width: 300px;
			text-align: center;
			@include breakpoint(below-desktop) {
				width: 30%;
			}
			@include breakpoint(below-small) {
				width: 60%;
				@include clfix;
				margin: 15px 0;
			}
			@include breakpoint(below-mobile) {
				width: 100%;
			}
		}
		img {
			display: block;
			margin: 0 auto;
			width: 140px;
			height: 140px;
			@include breakpoint(below-small) {
				display: inline-block;
				vertical-align: middle;
				width: 20%;
				height: auto;
			}
			@include breakpoint(below-mobile) {
				width: 22%;
			}
		}
		.content {
			@include breakpoint(below-small) {
				display: inline-block;
				vertical-align: middle;
				width: 65%;
				padding: 0 15px;
			}
			@include breakpoint(below-mobile) {
				width: 75%;
			}
		}
		.heading {
			color: #fff;
			margin: 20px 0;
			@include breakpoint(below-small) {
				margin: 10px 0;
			}
		}
	}

	.index-heading {
		color: #fff;
	}
}
