body {
	font-family: 	$english-font;
	font-weight:	$weight-l;
	color:			#666;
	font-size:		16px;

	background-color: #fff;
	height: 100%;
	overflow-x: hidden;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
	min-width: 320px;
	visibility: visible !important;
	opacity: 1 !important;
}

html[lang="en"] ,
html[lang="es"] {
	body {
		font-family: $english-font;
	}
}

html[lang="zh-Hans"] {
	body {
		font-family: $simplified-chinese-font;
	}
}

html[lang="zh"] {
	body {
		font-family: $base-font;
	}
}

html[lang="ja"] {
	body {
		font-family: $japanese-font;
	}
}

html.wf-active {
	visibility: visible;
	opacity: 1;
	body {
		visibility: visible;
		opacity: 1;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

.default-link {
	color: $blue;
}

i {
	display: inline-block;
	vertical-align: middle;
}

img {
	@extend %full-img;
}

hr {
	height: 1px;
	border: 0;
	background-color: #ccc;
	margin: 0;
}

.container,
.md-container,
.sm-container {
	@include breakpoint(below-medium) {
		padding: 0 15px;
	}
}

.container {
	@include container($inner-wrap);
}

.md-container {
	@include container($md-wrap);
}

.sm-container {
	@include container($sm-wrap);
}
