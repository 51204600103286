.not-found {
	min-height: 70vh;
	.heading {
		padding-top: 50px;
	}
	.back-home {
		padding-top: 50px;
		color: $red;
		&:hover {
			color: $black;
		}
		i {
			padding-right: 10px;
			margin-top: -4px;
		}
	}
	text-align: center;
}