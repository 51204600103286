%buttons {
	border-style: solid;
	border-width: 1px;
	display: block;
	max-width: 200px;
	padding: 12px 30px 10px 30px;
	font-size: 1em;
	margin: 0 auto;
	cursor: pointer;
	letter-spacing: .1em;
	text-align: center;
}

.btn-line-white-icon {
	@extend %buttons;
	color: #fff;
	border-color: #fff;
	@include bg-hover(transparent, rgba(0,0,0,.1));
	i, span {
		display: inline-block;
		vertical-align: middle;
	}
	i {
		font-size: 20px;
		margin-right: 10px;
	}
}

.btn-line-gray {
	@extend %buttons;
	margin: 30px auto 0 auto;
	border: 1px solid #999;
	@include bg-color-hover(transparent, #999, #fff);
	@include breakpoint(below-samll) {
		margin: 20px auto 0 auto;
	}
}

.btn-line-orange {
	@extend %buttons;
	color: #fff;
	@include bg-hover($orange, $orange - 20);
	margin-left: 0;
	min-width: 150px;
}

.btn-style-01-icon {
	@extend %buttons;
	color: #fff;
	border: 0;
	@include bg-hover-darken($main-color, 20);
	i, span {
		display: inline-block;
		vertical-align: middle;
	}
	i {
		font-size: 20px;
		margin-right: 10px;
	}
}

.btn-style-01 {
	@extend %buttons;
	background-color: transparent;
	border: 2px solid $orange;
	color: $orange;
	font-size: 1.2em;

	@include breakpoint(above-desktop) {
		background-size: 200% 100%;
		background-image: linear-gradient(to right, transparent 50%, $orange 50%);
		transition: background-position .3s, cubic-bezier(0.19, 1, 0.22, 1) .8s, color .5s ease 0s, background-color .5s ease;
		&:hover {
			color: #fff;
			background-color: $orange;
			background-position: -100% 100%;
		}
	}
}

.btn-style-02 {
	@extend %buttons;
	margin: 50px 0 0 0;
	color: #fff;
	@include bg-hover(#999, #999 - 40);
}

.btn-icon {
	text-align: center;
	i {
		padding-right: 15px;
	}
}

.border-full-btn {
	padding: 15px;
	border: 2px solid $orange;
	width: 100%;
	color: $orange;
	font-size: 19px;
	transition: color 0.3s, background-color 0.3s;
	@include breakpoint(above-desktop) {
		&:hover {
			color: #fff;
			background-color: $orange;
		}
	}
}

.border-full-btn__icon {
	display: inline-block;
	vertical-align: middle;
	margin-right: 15px;
}

.border-full-btn__text {
	display: inline-block;
	vertical-align: middle;
}

