@charset "UTF-8";
@font-face {
  font-family: "icon";
  src: url("../fonts/icon.eot");
  src: url("../fonts/icon.eot?#iefix") format("eot"), url("../fonts/icon.woff") format("woff"), url("../fonts/icon.ttf") format("truetype"), url("../fonts/icon.svg#icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-left:before {
  content: "\EA01"; }

.icon-arrow-right:before {
  content: "\EA02"; }

.icon-burger:before {
  content: "\EA03"; }

.icon-camera:before {
  content: "\EA04"; }

.icon-check:before {
  content: "\EA05"; }

.icon-error:before {
  content: "\EA06"; }

.icon-global:before {
  content: "\EA07"; }

.icon-home:before {
  content: "\EA08"; }

.icon-location:before {
  content: "\EA09"; }

.icon-mail:before {
  content: "\EA0A"; }

.icon-phone:before {
  content: "\EA0B"; }

.icon-printer:before {
  content: "\EA0C"; }

.icon-search:before {
  content: "\EA0D"; }

.icon-time:before {
  content: "\EA0E"; }

.icon-x:before {
  content: "\EA0F"; }

/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *::before, *::after {
  box-sizing: border-box; }

div, nav {
  backface-visibility: hidden; }

button,
[type="text"],
[type="tel"],
[type="email"],
[type="url"],
[type="submit"],
textarea {
  outline: 0;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  border-radius: 0;
  color: inherit;
  -webkit-appearance: none; }

select {
  border: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0; }

button {
  cursor: pointer; }

a,
input:focus,
textarea:focus,
select:focus {
  outline: none; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #eee inset; }

.dropdown__text, .two-blocks-01 .author {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

img, .two-blocks-01__image img, .two-blocks-01__text img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }

.bg-carousel__element, .two-blocks-01__image, .page-banner .image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.float-none {
  float: none; }

.align-center,
.form-sent .btn-line-orange {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.no-margin {
  margin: 0; }

.inline {
  display: inline; }

.show {
  display: block;
  visibility: visible; }

.hide {
  display: none !important;
  visibility: hidden !important; }

.screen-reader,
.text-hide {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.full-width {
  width: 100%; }

img.full-width {
  max-width: 100%;
  height: auto;
  display: block;
  width: auto; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.weight-light {
  font-weight: 300; }

.weight-normal {
  font-weight: 400; }

.weight-semibold {
  font-weight: 600; }

.text-uppercase {
  text-transform: uppercase; }

.caret {
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  display: inline-block;
  margin: 0 0 3px 4px;
  vertical-align: middle;
  width: 0; }

.table {
  display: table;
  width: 100%;
  height: 100%; }

.table-cell {
  display: table-cell;
  vertical-align: middle; }

body {
  font-family: "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  color: #666;
  font-size: 16px;
  background-color: #fff;
  height: 100%;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  min-width: 320px;
  visibility: visible !important;
  opacity: 1 !important; }

html[lang="en"] body,
html[lang="es"] body {
  font-family: "Helvetica", "Arial", sans-serif; }

html[lang="zh-Hans"] body {
  font-family: "Helvetica", "Arial", "Microsoft JhengHei", "微软正黑体", "Microsoft YaHei", "微软雅黑体", sans-serif; }

html[lang="zh"] body {
  font-family: "Helvetica", "Arial", "Microsoft JhengHei", "微軟正黑體", sans-serif; }

html[lang="ja"] body {
  font-family: "Helvetica", "Arial", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif; }

html.wf-active {
  visibility: visible;
  opacity: 1; }
  html.wf-active body {
    visibility: visible;
    opacity: 1; }

a {
  color: inherit;
  text-decoration: none; }

.default-link {
  color: #1d70b7; }

i {
  display: inline-block;
  vertical-align: middle; }

hr {
  height: 1px;
  border: 0;
  background-color: #ccc;
  margin: 0; }

@media (max-width: 1250px) {
  .container,
  .md-container,
  .sm-container {
    padding: 0 15px; } }

.container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .container:after {
    content: " ";
    display: block;
    clear: both; }

.md-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto; }
  .md-container:after {
    content: " ";
    display: block;
    clear: both; }

.sm-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }
  .sm-container:after {
    content: " ";
    display: block;
    clear: both; }

[class*="heading"] {
  font-weight: bold;
  line-height: 1.3em; }
  @media (min-width: 1025px) {
    [class*="heading"] a:hover {
      color: #ff7e27; } }

p, .disc-list > li, .events-list-01 .sub, .main-article__content ul > li, .main-article__content ol > li {
  font-size: 1.05em;
  line-height: 1.5em; }

.catalogue-description .heading, .product-description .heading, .sitemap-wrapper > .heading {
  font-size: 2em;
  color: #ff7e27;
  margin-bottom: 10px; }

a {
  transition: color .3s; }

h2 {
  font-size: 2.25em;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.3em; }
  @media (max-width: 850px) {
    h2 {
      font-size: 2em; } }

h3 {
  font-size: 1.5em;
  line-height: 1.2em; }
  @media (max-width: 850px) {
    h3 {
      font-size: 1.3em; } }

.orange-hr {
  background-color: #FFDEC8;
  height: 3px; }

.disc-list {
  padding-left: 1.35em; }
  .disc-list > li {
    list-style: disc;
    margin: 5px 0; }

.highlight {
  color: #ff7e27; }

.two-blocks-01 .date, .two-blocks-01 .author, .two-blocks-01 .type, .event-data-list, .three-cols .date, .main-article .date, .main-article .author {
  font-size: 1em;
  color: #999;
  line-height: 1.3em; }

.two-blocks-01 .heading, .three-cols .heading {
  margin: 10px 0;
  transition: color .3s;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 2.5em; }
  @media (max-width: 850px) {
    .two-blocks-01 .heading, .three-cols .heading {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 3.8em; } }

.two-blocks-01 .description, .three-cols .description {
  margin-bottom: 10px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 6em; }
  @media (max-width: 1024px) {
    .two-blocks-01 .description, .three-cols .description {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 4.5em; } }
  @media (max-width: 850px) {
    .two-blocks-01 .description, .three-cols .description {
      display: none; } }

.icon-location,
.icon-time {
  font-size: 18px;
  width: 18px;
  text-align: center;
  margin-right: 5px; }

.btn-line-white-icon, .btn-line-gray, .btn-line-orange, .btn-style-01-icon, .btn-style-01, .btn-style-02 {
  border-style: solid;
  border-width: 1px;
  display: block;
  max-width: 200px;
  padding: 12px 30px 10px 30px;
  font-size: 1em;
  margin: 0 auto;
  cursor: pointer;
  letter-spacing: .1em;
  text-align: center; }

.btn-line-white-icon {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
  transition: background-color .25s; }
  @media (min-width: 1025px) {
    .btn-line-white-icon:hover {
      background-color: rgba(0, 0, 0, 0.1); } }
  .btn-line-white-icon i, .btn-line-white-icon span {
    display: inline-block;
    vertical-align: middle; }
  .btn-line-white-icon i {
    font-size: 20px;
    margin-right: 10px; }

.btn-line-gray {
  margin: 30px auto 0 auto;
  border: 1px solid #999;
  background-color: transparent;
  transition: background-color .25s, color .25s; }
  @media (min-width: 1025px) {
    .btn-line-gray:hover {
      color: #fff;
      background-color: #999; } }

.btn-line-orange {
  color: #fff;
  background-color: #ff7e27;
  transition: background-color .25s;
  margin-left: 0;
  min-width: 150px; }
  @media (min-width: 1025px) {
    .btn-line-orange:hover {
      background-color: #eb6a13; } }

.btn-style-01-icon {
  color: #fff;
  border: 0;
  background-color: #ff7e27;
  transition: background-color .25s; }
  @media (min-width: 1025px) {
    .btn-style-01-icon:hover {
      background-color: #eb6a13; } }
  .btn-style-01-icon i, .btn-style-01-icon span {
    display: inline-block;
    vertical-align: middle; }
  .btn-style-01-icon i {
    font-size: 20px;
    margin-right: 10px; }

.btn-style-01 {
  background-color: transparent;
  border: 2px solid #ff7e27;
  color: #ff7e27;
  font-size: 1.2em; }
  @media (min-width: 1025px) {
    .btn-style-01 {
      background-size: 200% 100%;
      background-image: linear-gradient(to right, transparent 50%, #ff7e27 50%);
      transition: background-position 0.3s, cubic-bezier(0.19, 1, 0.22, 1) 0.8s, color 0.5s ease 0s, background-color 0.5s ease; }
      .btn-style-01:hover {
        color: #fff;
        background-color: #ff7e27;
        background-position: -100% 100%; } }

.btn-style-02 {
  margin: 50px 0 0 0;
  color: #fff;
  background-color: #999;
  transition: background-color .25s; }
  @media (min-width: 1025px) {
    .btn-style-02:hover {
      background-color: #717171; } }

.btn-icon {
  text-align: center; }
  .btn-icon i {
    padding-right: 15px; }

.border-full-btn {
  padding: 15px;
  border: 2px solid #ff7e27;
  width: 100%;
  color: #ff7e27;
  font-size: 19px;
  transition: color 0.3s, background-color 0.3s; }
  @media (min-width: 1025px) {
    .border-full-btn:hover {
      color: #fff;
      background-color: #ff7e27; } }

.border-full-btn__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px; }

.border-full-btn__text {
  display: inline-block;
  vertical-align: middle; }

.form-group {
  margin-bottom: 20px; }
  .form-group:before, .form-group:after {
    content: " ";
    display: table; }
  .form-group:after {
    clear: both; }
  .form-group__helf-item[type] {
    float: left;
    width: 48%; }
    .form-group__helf-item[type]:last-child {
      float: right; }
    @media (max-width: 850px) {
      .form-group__helf-item[type] {
        float: none;
        width: 100%; }
        .form-group__helf-item[type]:last-child {
          margin-top: 20px; } }

.radio-heading {
  display: inline-block;
  margin-right: 50px;
  font-weight: normal; }

.radio-group {
  display: inline-block;
  width: auto;
  margin-right: 20px;
  vertical-align: middle; }
  .radio-group label {
    cursor: pointer; }

/* event table */
.table-1 {
  width: 100%;
  margin-bottom: 30px; }
  .table-1 tr:first-child td, .table-1 tr:first-child th {
    padding-top: 20px; }
    @media (max-width: 550px) {
      .table-1 tr:first-child td, .table-1 tr:first-child th {
        padding-top: 15px; } }
  .table-1 tr:last-child td, .table-1 tr:last-child th {
    padding-bottom: 20px; }
    @media (max-width: 550px) {
      .table-1 tr:last-child td, .table-1 tr:last-child th {
        padding-bottom: 15px; } }
  .table-1 th, .table-1 td {
    padding: 10px 20px;
    background-color: #f2f2f2;
    line-height: 1.3em; }
    @media (max-width: 550px) {
      .table-1 th, .table-1 td {
        padding: 5px 10px; } }
  .table-1 th {
    text-align: left;
    width: 110px;
    font-weight: 600;
    padding-right: 0px; }
    @media (max-width: 550px) {
      .table-1 th {
        width: 50px; } }

/* product table style */
.product-table {
  padding: 40px 0; }

.product-table__heading {
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 900px; }
  .product-table__heading.orange {
    color: #ff7e27; }

.porduct-list {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #aaa;
  overflow: hidden; }
  .porduct-list [class*="col"] {
    padding: 20px 8px;
    display: table-cell;
    vertical-align: middle;
    line-height: 1.3em; }
    @media (max-width: 550px) {
      .porduct-list [class*="col"] {
        padding: 10px 6px; } }
  .porduct-list .col-1 {
    width: 20%;
    border-right: 1px solid #aaa; }
    @media (max-width: 850px) {
      .porduct-list .col-1 {
        width: 30%; } }
  .porduct-list .col-2 {
    width: 50%;
    border-right: 1px solid #aaa; }
    @media (max-width: 850px) {
      .porduct-list .col-2 {
        width: 40%; } }
    @media (max-width: 550px) {
      .porduct-list .col-2 {
        width: 30%; } }
  .porduct-list .col-3 {
    width: 30%; }
    @media (max-width: 550px) {
      .porduct-list .col-3 {
        width: 40%; } }
  .porduct-list__heading {
    background-color: #1d1d1b;
    color: #fff;
    display: table;
    table-layout: fixed;
    width: 100%;
    letter-spacing: 0.04em;
    font-size: 1.2em;
    font-weight: normal; }
    @media (max-width: 550px) {
      .porduct-list__heading {
        font-size: .95em; } }
  .porduct-list__product {
    display: table;
    table-layout: fixed;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: background-color .2s; }
    .porduct-list__product:nth-child(odd) {
      background-color: #f5f5f5; }
    .porduct-list__product:hover {
      background-color: #e1e1e1; }
      .porduct-list__product:hover .product-name {
        background-color: #eb6a13; }
    @media (max-width: 550px) {
      .porduct-list__product {
        font-size: 0.95em; } }
  .porduct-list__app .col-1 {
    width: 20%; }
    @media (max-width: 850px) {
      .porduct-list__app .col-1 {
        width: 30%; } }
  .porduct-list__app .col-2 {
    width: 80%;
    border-right: 0;
    text-align: left;
    padding: 20px; }
    @media (max-width: 850px) {
      .porduct-list__app .col-2 {
        width: 70%; } }
  .porduct-list .product-name {
    background-color: #ff7e27;
    color: #fff;
    transition: background-color .2s; }
  .porduct-list .name {
    color: #fff;
    font-size: 1.1em;
    word-break: break-word; }
    @media (max-width: 550px) {
      .porduct-list .name {
        font-size: 1em; } }

[data-lang="jp"] .product-name .name {
  font-size: 0.95em; }

.product-main {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 20px; }
  .product-main__element {
    margin-bottom: 5px;
    display: table;
    table-layout: fixed;
    width: 100%; }
    @media (max-width: 550px) {
      .product-main__element {
        display: block;
        margin-bottom: 20px; } }
  .product-main [class*="col"] {
    display: table-cell;
    vertical-align: middle;
    line-height: 1.5em; }
    @media (max-width: 550px) {
      .product-main [class*="col"] {
        width: 100%;
        display: block; } }
    .product-main [class*="col"] > p {
      padding: 10px 15px; }
  .product-main .col-1 {
    width: 40%;
    border-right: 5px solid #fff;
    background-color: #1d1d1b; }
    .product-main .col-1 p {
      color: #fff; }
    @media (max-width: 850px) {
      .product-main .col-1 {
        width: 25%; } }
    @media (max-width: 550px) {
      .product-main .col-1 {
        border-right: 0;
        width: 100%; } }
  .product-main .col-2 {
    width: 60%;
    background-color: #f0f0f0;
    position: relative; }
    .product-main .col-2 p {
      color: #1d1d1b; }
    @media (max-width: 850px) {
      .product-main .col-2 {
        width: 75%; } }
    @media (max-width: 550px) {
      .product-main .col-2 {
        width: 100%; } }
  .product-main .product-image {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    mix-blend-mode: multiply;
    margin: 0 auto; }
  .product-main .product-contact-us {
    background-color: #ff7e27;
    transition: background-color .25s;
    line-height: 45px;
    width: 100%;
    color: #fff;
    text-align: center; }
    @media (min-width: 1025px) {
      .product-main .product-contact-us:hover {
        background-color: #eb6a13; } }
  .product-main .structure-txt {
    display: inline-block;
    margin-top: 20px;
    font-size: .8em;
    line-height: 1.2em;
    color: #6c6e70; }

::-webkit-input-placeholder {
  color: #888; }

:-moz-placeholder {
  color: #888; }

::-moz-placeholder {
  color: #888; }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
  width: 100%;
  height: 48px;
  border: 1px solid #ddd;
  text-align: left;
  line-height: 1em;
  color: #1d1d1b;
  padding: 0 10px;
  padding-top: 4px;
  background-color: #f5f5f5; }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus {
    border-color: #999; }
  input[type="text"].warning,
  input[type="password"].warning,
  input[type="email"].warning,
  input[type="tel"].warning {
    border-color: #DA4E4E; }

input[type="submit"] {
  cursor: pointer; }
  input[type="submit"].disabled, input[type="submit"]:disabled {
    pointer-events: none;
    opacity: .5; }

button.disabled,
button:disabled {
  pointer-events: none;
  opacity: .5; }

input[type="radio"] {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px 0 0;
  padding: 0; }

label {
  display: inline-block;
  margin-bottom: 8px; }

textarea {
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  width: 100%;
  max-width: 100%;
  min-height: 150px;
  padding: 15px 10px;
  color: #1d1d1b; }
  textarea:focus {
    border-color: #999; }
  textarea.warning {
    border-color: #DA4E4E; }

.search-input {
  position: relative; }
  .search-input input[type="text"] {
    border: 0;
    background-color: #f5f5f5;
    color: #565756;
    height: 60px;
    line-height: 60px; }
    @media (max-width: 850px) {
      .search-input input[type="text"] {
        background-color: #333;
        color: #C4C4C4;
        height: 40px;
        line-height: 40px; } }
  .search-input input[type="submit"] {
    position: absolute;
    width: 100px;
    text-align: center;
    color: #fff;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #b12c33;
    transition: background-color .25s;
    background-image: url("../img/icons/search.svg");
    background-size: 30px;
    background-position: 45% 45%;
    background-repeat: no-repeat; }
    @media (min-width: 1025px) {
      .search-input input[type="submit"]:hover {
        background-color: #930e15; } }
    @media (max-width: 850px) {
      .search-input input[type="submit"] {
        width: 60px;
        color: #fff;
        background-size: 35% 35%; } }

.disabled input[type="text"],
.disabled input[type="password"],
.disabled input[type="email"],
.disabled input[type="tel"],
.disabled .dropdown {
  border: 2px solid #eee;
  color: #eee;
  pointer-events: none; }

.dropdown {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 240px;
  height: 40px;
  background-color: #fff;
  text-align: left;
  position: relative; }
  .dropdown:before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    right: 15px;
    top: 50%;
    margin-top: -5px;
    background: url("../img/common/dropdown.svg") 0 0 no-repeat; }
  .dropdown__text {
    display: block;
    padding-left: 10px;
    vertical-align: middle;
    line-height: 40px;
    width: 90%; }
  .dropdown__list {
    position: absolute;
    left: 0;
    top: 40px;
    background-color: #f2f2f2;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: 10px 0;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: opacity .15s;
    max-height: 50vh;
    overflow-y: auto; }
    @media (max-width: 850px) {
      .dropdown__list {
        max-height: 300px;
        overflow-y: auto; } }
    .dropdown__list > li {
      cursor: pointer;
      padding: 12px;
      position: relative;
      line-height: 1.2em; }
      .dropdown__list > li:after {
        teansition: opacity 2s;
        opacity: 0;
        position: absolute;
        top: 50%;
        right: 10px;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        background: url("../img/common/check.svg") 0 0 no-repeat;
        margin-top: -5px; }
      .dropdown__list > li:hover {
        background-color: #e0e0e0;
        transition: background .25s; }
        .dropdown__list > li:hover:after {
          opacity: 1; }
  .dropdown.active .dropdown__list {
    pointer-events: auto;
    opacity: 1; }
  .dropdown.disabled {
    border: 2px solid #eee;
    color: #eee;
    pointer-events: none; }
  .dropdown.dropdown-gray {
    background-color: #ddd; }

[data-value="hide"] {
  display: none !important; }

.pagination__list {
  display: table;
  margin: 20px auto 0 auto; }
  .pagination__list:before, .pagination__list:after {
    content: " ";
    display: table; }
  .pagination__list:after {
    clear: both; }
  .pagination__list > li {
    float: left;
    border: 1px solid #b12c33;
    border-right: 0;
    font-size: 1em;
    background-color: #fff;
    cursor: pointer; }
    .pagination__list > li:last-child {
      border-right: 1px solid #b12c33; }
    .pagination__list > li:hover {
      background-color: #eee; }
    .pagination__list > li a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 7px 10px;
      color: #b12c33; }
    .pagination__list > li i {
      font-size: 12px;
      margin-top: -3px;
      color: #b12c33; }
  .pagination__list .control {
    padding-left: 3px;
    padding-right: 3px; }
    @media (max-width: 850px) {
      .pagination__list .control {
        padding: 3px 7px; } }
  .pagination__list .active a {
    background: #b12c33;
    color: #fff; }
  @media (max-width: 850px) {
    .pagination__list .page {
      display: none; } }

.sisea-paging {
  text-align: center; }
  .sisea-paging .sisea-page {
    display: inline-block; }
    .sisea-paging .sisea-page a {
      padding: 10px; }
  .sisea-paging .sisea-current-page {
    color: #b12c33;
    padding: 10px; }

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 50%;
  z-index: 99;
  font-size: 40px;
  background-color: transparent;
  height: 100px;
  margin-top: -50px;
  width: 50px;
  text-align: center;
  line-height: 100px;
  color: #fff;
  cursor: pointer;
  transition: background-color .5s; }
  @media (min-width: 1025px) {
    .carousel-prev:hover,
    .carousel-next:hover {
      background-color: rgba(0, 0, 0, 0.4); } }
  @media (max-width: 850px) {
    .carousel-prev,
    .carousel-next {
      background: transparent;
      font-size: 30px;
      width: 40px; } }

.carousel-prev {
  left: 0; }

.carousel-next {
  right: 0; }

.bg-carousel {
  position: relative;
  height: 750px;
  max-height: calc(100vh - 85px); }
  @media (max-width: 1024px) {
    .bg-carousel {
      height: 450px; } }
  @media (max-width: 850px) {
    .bg-carousel {
      height: 300px; } }
  @media (max-width: 550px) {
    .bg-carousel {
      height: 235px; } }
  .bg-carousel__element {
    background-color: #f2f2f2;
    width: 100%; }
  .bg-carousel a {
    display: block;
    height: 100%; }

.two-block-slider {
  height: 400px; }
  @media (max-width: 850px) {
    .two-block-slider {
      height: 300px; } }
  .two-block-slider__elememt {
    height: 100%;
    position: relative; }
    .two-block-slider__elememt:before, .two-block-slider__elememt:after {
      content: " ";
      display: table; }
    .two-block-slider__elememt:after {
      clear: both; }
  .two-block-slider__image, .two-block-slider__text {
    width: 50%;
    float: left;
    height: 100%; }
    @media (max-width: 850px) {
      .two-block-slider__image, .two-block-slider__text {
        width: 100%;
        float: none; } }
  .two-block-slider__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%; }
    @media (max-width: 850px) {
      .two-block-slider__image {
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0; } }
  .two-block-slider__text {
    background-color: #ff7e27;
    padding: 60px; }
    @media (max-width: 850px) {
      .two-block-slider__text {
        padding: 40px;
        background-color: rgba(0, 0, 0, 0.5); } }
  .two-block-slider .heading {
    font-size: 3.8em;
    line-height: 1em;
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFDD00;
    hyphens: auto; }
    @media (max-width: 1250px) {
      .two-block-slider .heading {
        font-size: 2.5em; } }
    @media (max-width: 850px) {
      .two-block-slider .heading {
        font-size: 2em; } }
  .two-block-slider .sub-heading {
    color: #fff;
    font-size: 1.5em;
    line-height: 1.3em;
    margin-top: 30px;
    font-weight: normal;
    letter-spacing: 1px; }
    @media (max-width: 1250px) {
      .two-block-slider .sub-heading {
        font-size: 1.2em; } }
    @media (max-width: 850px) {
      .two-block-slider .sub-heading {
        margin-top: 20px; } }
    @media (max-width: 550px) {
      .two-block-slider .sub-heading {
        margin-top: 15px;
        font-size: 1em; } }

.post-carousel__item {
  padding: 0 15px;
  outline: none; }

.post-carousel__link {
  display: block; }

.post-carousel__tag {
  position: relative;
  display: inline-block;
  padding: 8px 10px;
  color: white;
  background-color: #C4C4C4;
  z-index: 5; }

.post-carousel__img {
  display: block;
  transform: translateY(-18px); }

.post-carousel__heading {
  font-size: 18px; }

.post-carousel__btn {
  z-index: 20;
  position: absolute;
  top: calc(50% - 20px); }
  .post-carousel__btn.btn--prev {
    left: -40px; }
    @media (max-width: 850px) {
      .post-carousel__btn.btn--prev {
        left: 0; } }
  .post-carousel__btn.btn--next {
    right: -40px; }
    @media (max-width: 850px) {
      .post-carousel__btn.btn--next {
        right: 0; } }

.post-carousel__icon {
  font-size: 40px; }

.tabs {
  text-align: right;
  margin-bottom: 17px; }
  @media (max-width: 850px) {
    .tabs {
      margin-bottom: 12px; } }
  .tabs .tabs-item {
    display: inline-block;
    color: #6c6e70; }
    .tabs .tabs-item:hover {
      color: #ff7e27; }
    .tabs .tabs-item a {
      display: inline-block;
      padding: 5px; }
  .tabs .active {
    color: #ff7e27;
    pointer-events: none; }

.breadcrumb {
  margin-bottom: 22px; }
  .breadcrumb li {
    display: inline-block;
    font-size: .9em;
    line-height: 1.3em; }
    .breadcrumb li:last-child {
      color: #b12c33; }
    .breadcrumb li a:hover {
      color: #C4C4C4; }

.catalogue .breadcrumb li:nth-child(2),
.catalogue .breadcrumb li:nth-child(3),
.product .breadcrumb li:nth-child(2),
.product .breadcrumb li:nth-child(3) {
  pointer-events: none; }

.article-single .B_crumbBox li:last-child {
  display: none; }

.two-blocks-01:before, .two-blocks-01:after {
  content: " ";
  display: table; }

.two-blocks-01:after {
  clear: both; }

.two-blocks-01__item {
  background-color: #fff;
  margin-bottom: 1.69492%;
  height: 280px;
  overflow: hidden;
  font-size: .85em;
  position: relative;
  width: 49.15254%;
  float: left;
  margin-right: 1.69492%; }
  .two-blocks-01__item:before, .two-blocks-01__item:after {
    content: " ";
    display: table; }
  .two-blocks-01__item:after {
    clear: both; }
  .two-blocks-01__item:nth-child(2n) {
    width: 49.15254%;
    float: right;
    margin-right: 0; }
  .two-blocks-01__item:nth-child(4n-1) .two-blocks-01__image,
  .two-blocks-01__item:nth-child(4n-1) .two-blocks-01__text, .two-blocks-01__item:nth-child(4n) .two-blocks-01__image,
  .two-blocks-01__item:nth-child(4n) .two-blocks-01__text {
    float: right; }
  @media (max-width: 1024px) {
    .two-blocks-01__item {
      font-size: 1em;
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
      .two-blocks-01__item:nth-child(2n) {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }
  @media (max-width: 850px) {
    .two-blocks-01__item {
      height: 200px;
      margin: 0; }
      .two-blocks-01__item:nth-child(4n-1) .two-blocks-01__image,
      .two-blocks-01__item:nth-child(4n-1) .two-blocks-01__text, .two-blocks-01__item:nth-child(4n) .two-blocks-01__image,
      .two-blocks-01__item:nth-child(4n) .two-blocks-01__text {
        float: left; }
      .two-blocks-01__item:nth-child(2n) .two-blocks-01__image,
      .two-blocks-01__item:nth-child(2n) .two-blocks-01__text {
        float: right; } }
  @media (min-width: 1025px) {
    .two-blocks-01__item:hover .heading {
      color: #ff7e27; } }
  .two-blocks-01__item > a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-indent: -999px; }
    .two-blocks-01__item > a:after {
      content: "";
      position: absolute;
      background-color: #ff7e27;
      height: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: height .3s; }
    @media (min-width: 1025px) {
      .two-blocks-01__item > a:hover:after {
        height: 12px; } }

.two-blocks-01__image, .two-blocks-01__text {
  float: left;
  width: 50%;
  height: 100%;
  padding: 22px; }
  @media (max-width: 550px) {
    .two-blocks-01__image, .two-blocks-01__text {
      padding: 15px; } }

@media (max-width: 550px) {
  .two-blocks-01 .heading {
    font-size: 1em; } }

@media (max-width: 850px) {
  .two-blocks-01 .date, .two-blocks-01 .author, .two-blocks-01 .type {
    font-size: .9em; } }

.two-blocks-01 .author {
  margin-bottom: 5px; }

.large-search-block {
  padding: 60px 0;
  background-color: #ff7e27;
  background-image: url("../img/science-bg.svg");
  background-repeat: no-repeat;
  background-size: cover; }
  .large-search-block .three-search-block {
    text-align: center;
    margin-bottom: 40px; }
    .large-search-block .three-search-block > li {
      display: inline-block;
      width: 300px;
      text-align: center; }
      @media (max-width: 1024px) {
        .large-search-block .three-search-block > li {
          width: 30%; } }
      @media (max-width: 850px) {
        .large-search-block .three-search-block > li {
          width: 60%;
          margin: 15px 0; }
          .large-search-block .three-search-block > li:before, .large-search-block .three-search-block > li:after {
            content: " ";
            display: table; }
          .large-search-block .three-search-block > li:after {
            clear: both; } }
      @media (max-width: 550px) {
        .large-search-block .three-search-block > li {
          width: 100%; } }
    .large-search-block .three-search-block img {
      display: block;
      margin: 0 auto;
      width: 140px;
      height: 140px; }
      @media (max-width: 850px) {
        .large-search-block .three-search-block img {
          display: inline-block;
          vertical-align: middle;
          width: 20%;
          height: auto; } }
      @media (max-width: 550px) {
        .large-search-block .three-search-block img {
          width: 22%; } }
    @media (max-width: 850px) {
      .large-search-block .three-search-block .content {
        display: inline-block;
        vertical-align: middle;
        width: 65%;
        padding: 0 15px; } }
    @media (max-width: 550px) {
      .large-search-block .three-search-block .content {
        width: 75%; } }
    .large-search-block .three-search-block .heading {
      color: #fff;
      margin: 20px 0; }
      @media (max-width: 850px) {
        .large-search-block .three-search-block .heading {
          margin: 10px 0; } }
  .large-search-block .index-heading {
    color: #fff; }

.events-list-01:before, .events-list-01:after {
  content: " ";
  display: table; }

.events-list-01:after {
  clear: both; }

.events-list-01 > div:first-child {
  width: 48.57143%;
  float: left;
  margin-right: 2.85714%;
  position: relative; }
  @media (max-width: 850px) {
    .events-list-01 > div:first-child {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

.events-list-01 > div:last-child {
  width: 48.57143%;
  float: right;
  margin-right: 0; }
  @media (max-width: 850px) {
    .events-list-01 > div:last-child {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-top: 30px; } }

.events-list-01__text {
  background-color: #fff;
  padding: 22px; }
  .events-list-01__text .event-data-list {
    margin-top: 30px; }

.events-list-01 .heading {
  margin-bottom: 10px; }

.events-list-01 .date-lable {
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  background-color: #ff7e27;
  color: #fff; }

.events-list__item {
  padding: 22px 0;
  border-top: 1px solid #ddd;
  position: relative; }
  .events-list__item:before, .events-list__item:after {
    content: " ";
    display: table; }
  .events-list__item:after {
    clear: both; }
  .events-list__item:first-child {
    display: none; }
  .events-list__item:nth-child(2) {
    padding-top: 0;
    border-top: 0; }

.events-list .col-1 {
  width: 110px;
  position: absolute; }

.events-list .col-2 {
  width: 100%;
  padding-left: 110px; }

.events-list .heading {
  font-size: 1.3em;
  font-weight: bold; }

.events-list .event-data-list > li {
  display: block; }

.event-data-list > li {
  display: inline-block;
  margin: 5px; }
  .event-data-list > li:first-child {
    margin-right: 20px; }

.style-cal {
  background-color: #fff;
  width: 85px;
  height: 85px;
  text-align: center;
  position: relative; }
  .style-cal:before {
    content: "";
    background-color: #ff7e27;
    width: 40px;
    height: 3px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -20px; }
  .style-cal .month {
    display: block;
    font-size: 35px;
    padding-top: 15px; }
  .style-cal .date {
    color: #666;
    display: block;
    margin-top: 5px; }

.page-banner {
  height: 450px; }
  @media (max-width: 850px) {
    .page-banner {
      height: 300px; } }
  @media (max-width: 550px) {
    .page-banner {
      height: 200px; } }
  .page-banner .image {
    display: table;
    width: 100%;
    height: 100%; }
    .page-banner .image > div {
      height: 100%;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.1); }
      .page-banner .image > div .page-heading {
        line-height: 100%;
        font-size: 2.8em;
        text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5); }
        @media (max-width: 850px) {
          .page-banner .image > div .page-heading {
            font-size: 2.3em; } }
        @media (max-width: 850px) {
          .page-banner .image > div .page-heading {
            font-size: 1.8em; } }

#index-banner {
  text-align: right; }
  #index-banner .container {
    height: 100%; }
  #index-banner .table {
    width: 50%; }
    @media (max-width: 1250px) {
      #index-banner .table {
        width: 60%; } }
    @media (max-width: 850px) {
      #index-banner .table {
        width: 70%; } }
    @media (max-width: 550px) {
      #index-banner .table {
        width: 100%; } }
  #index-banner .heading {
    font-size: 3.8em;
    color: #ff7e27;
    font-weight: bold;
    line-height: 1.1em; }
    @media (max-width: 1024px) {
      #index-banner .heading {
        font-size: 3.2em; } }
    @media (max-width: 850px) {
      #index-banner .heading {
        font-size: 2.2em; } }
    @media (max-width: 550px) {
      #index-banner .heading {
        font-size: 1.8em; } }
  #index-banner .sub-heading {
    font-size: 2.2em;
    color: #fff;
    letter-spacing: .5em;
    margin-right: -.5em;
    margin-top: 10px;
    font-weight: bold; }
    @media (max-width: 850px) {
      #index-banner .sub-heading {
        font-size: 1.8em; } }
    @media (max-width: 550px) {
      #index-banner .sub-heading {
        font-size: 1.1em; } }
  #index-banner .paragraph--white,
  #index-banner .paragraph--gray {
    letter-spacing: .05em;
    margin-top: 10px;
    font-size: 1.4em;
    line-height: 1.3em; }
    @media (max-width: 850px) {
      #index-banner .paragraph--white,
      #index-banner .paragraph--gray {
        font-size: 1em; } }
    @media (max-width: 850px) {
      #index-banner .paragraph--white,
      #index-banner .paragraph--gray {
        font-size: .9em; } }
  #index-banner .paragraph--white {
    color: #fff; }
  #index-banner .paragraph--gray {
    color: #6c6e70; }

.bg-carousel__title {
  text-align: left;
  display: inline-block;
  position: absolute;
  top: 20%;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px 80px 40px 40px; }
  @media (max-width: 767px) {
    .bg-carousel__title {
      top: auto;
      bottom: 0;
      padding: 10px; } }
  .bg-carousel__title p {
    letter-spacing: .05em;
    margin-top: 10px;
    font-size: 1.4em;
    line-height: 1.3em; }
    @media (max-width: 767px) {
      .bg-carousel__title p {
        font-size: 1em; } }
  .bg-carousel__title h2 {
    color: #ff7e27; }
    @media (max-width: 767px) {
      .bg-carousel__title h2 {
        font-size: 1.5em; } }

.left-bottom .table-cell {
  vertical-align: bottom;
  padding-right: 30px;
  padding-bottom: 90px; }
  @media (max-width: 850px) {
    .left-bottom .table-cell {
      padding-bottom: 30px; } }
  @media (max-width: 550px) {
    .left-bottom .table-cell {
      padding-bottom: 20px; } }

.left-middle .table-cell {
  vertical-align: middle;
  padding-bottom: 0; }
  @media (max-width: 850px) {
    .left-middle .table-cell {
      padding-right: 15px; } }

.right-middle .table {
  float: right; }

@media (min-width: 1025px) {
  .right-middle .table-cell {
    vertical-align: middle;
    padding-bottom: 0;
    text-align: right;
    padding-right: 50px; } }

@media (max-width: 1024px) {
  .right-middle .table-cell {
    padding-right: 30px; } }

@media (max-width: 550px) {
  .right-middle .table-cell {
    padding-right: 15px; } }

.home-kv-text--white,
.home-kv-text--gray,
.en-home-kv-text--gray,
.en-home-kv-text--white {
  display: inline-block;
  height: 105px;
  width: 550px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 0;
  margin-bottom: 20px; }
  @media (max-width: 1024px) {
    .home-kv-text--white,
    .home-kv-text--gray,
    .en-home-kv-text--gray,
    .en-home-kv-text--white {
      width: 430px;
      height: 80px;
      margin-bottom: 15px; } }
  @media (max-width: 850px) {
    .home-kv-text--white,
    .home-kv-text--gray,
    .en-home-kv-text--gray,
    .en-home-kv-text--white {
      width: 380px;
      margin-bottom: 0px; } }
  @media (max-width: 550px) {
    .home-kv-text--white,
    .home-kv-text--gray,
    .en-home-kv-text--gray,
    .en-home-kv-text--white {
      width: 280px;
      height: 55px; } }

.en-home-kv-text--gray,
.en-home-kv-text--white {
  height: 65px; }
  @media (max-width: 1024px) {
    .en-home-kv-text--gray,
    .en-home-kv-text--white {
      width: 430px;
      height: 48px;
      margin-bottom: 15px; } }
  @media (max-width: 850px) {
    .en-home-kv-text--gray,
    .en-home-kv-text--white {
      width: 380px;
      height: 47px;
      margin-bottom: 0px; } }
  @media (max-width: 550px) {
    .en-home-kv-text--gray,
    .en-home-kv-text--white {
      width: 280px;
      height: 32px; } }

.home-kv-text--gray {
  background-image: url("../img/common/everlight-banner-gray.svg"); }

.home-kv-text--white {
  background-image: url("../img/common/everlight-banner-white.svg"); }

.en-home-kv-text--gray {
  background-image: url("../img/common/en-everlight-banner-gray.svg"); }

.en-home-kv-text--white {
  background-image: url("../img/common/en-everlight-banner-white.svg"); }

#kv-1 {
  background-image: url("/themes/img/banner-01.jpg"); }
  @media (max-width: 550px) {
    #kv-1 {
      background-image: url("/themes/img/banner-01-s.jpg"); } }

#kv-2 {
  background-image: url("/themes/img/banner-02.jpg"); }
  @media (max-width: 550px) {
    #kv-2 {
      background-image: url("/themes/img/banner-02-s.jpg"); } }

#kv-3 {
  background-image: url("/themes/img/banner-03.jpg"); }
  @media (max-width: 550px) {
    #kv-3 {
      background-image: url("/themes/img/banner-03-s.jpg"); } }

#kv-4 {
  background-image: url("/themes/img/banner-04.jpg"); }
  @media (max-width: 550px) {
    #kv-4 {
      background-image: url("/themes/img/banner-04-s.jpg"); } }

#kv-5 {
  background-image: url("/themes/img/banner-05.jpg"); }
  @media (max-width: 550px) {
    #kv-5 {
      background-image: url("/themes/img/banner-05-s.jpg"); } }

.article {
  background-color: #f5f5f5; }

.three-cols {
  margin-top: 22px; }
  .three-cols:before, .three-cols:after {
    content: " ";
    display: table; }
  .three-cols:after {
    clear: both; }
  @media (max-width: 850px) {
    .three-cols {
      margin-top: 12px; } }
  .three-cols__item {
    background-color: #fff;
    margin-bottom: 2.85714%;
    font-size: .85em;
    overflow: hidden;
    position: relative;
    padding: 22px; }
    @media (min-width: 851px) {
      .three-cols__item {
        width: 31.42857%;
        float: left;
        margin-right: 2.85714%; }
        .three-cols__item:nth-child(3n) {
          width: 31.42857%;
          float: right;
          margin-right: 0; } }
    @media (max-width: 850px) {
      .three-cols__item {
        width: 48.57143%;
        float: left;
        margin-right: 2.85714%;
        padding: 12px;
        font-size: .75em; }
        .three-cols__item:nth-child(2n) {
          width: 48.57143%;
          float: right;
          margin-right: 0; } }
    .three-cols__item > a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-indent: -999px; }
      .three-cols__item > a:after {
        content: "";
        position: absolute;
        background-color: #ff7e27;
        height: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: height .3s; }
      @media (min-width: 1025px) {
        .three-cols__item > a:hover:after {
          height: 12px; } }
    @media (min-width: 1025px) {
      .three-cols__item:hover .heading {
        color: #ff7e27; } }
  .three-cols__image {
    margin-bottom: 15px; }
  @media (max-width: 850px) {
    .three-cols .date {
      font-size: .9em; } }
  .three-cols .read-more {
    text-transform: uppercase;
    border: 1px solid #ff7e27;
    color: #ff7e27;
    padding: 5px 10px;
    margin-bottom: 5px;
    display: inline-block; }
    @media (max-width: 850px) {
      .three-cols .read-more {
        display: none; } }

.body-loader {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #222;
  z-index: 999;
  transition: opacity .3s, visibility .3s; }
  .body-loader.is-active {
    opacity: 1;
    visibility: visible; }

.body-loader__inner {
  width: 130px;
  margin: 0 auto;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -65px;
  margin-top: -65px; }

.body-loader__logo {
  width: 100%; }

.loading-animation {
  width: 80px;
  margin: 20px auto; }
  .loading-animation:before, .loading-animation:after {
    content: " ";
    display: table; }
  .loading-animation:after {
    clear: both; }
  .loading-animation li {
    height: 0;
    display: block;
    float: left;
    margin: 0 8px;
    height: 10px;
    width: 10px;
    border: 3px solid #fff;
    border-radius: 100%;
    transform: transformZ(0);
    animation: LOADING 2s infinite;
    background-color: #fff; }
    .loading-animation li:first-child {
      animation-delay: 0s; }
    .loading-animation li:nth-child(2n) {
      animation-delay: 0.2s; }
    .loading-animation li:nth-child(3n) {
      animation-delay: 0.4s; }

@keyframes LOADING {
  0% {
    transform: scale(0.5); }
  50% {
    transform: scale(1); }
  100% {
    transform: scale(0.5); } }

@keyframes LOADINGREV {
  0% {
    transform: scale(0.5); }
  50% {
    transform: scale(1); }
  100% {
    transform: scale(0.5); } }

.footer {
  background-color: #1d1d1b;
  border-top: 5px solid #ff7e27;
  color: #C4C4C4; }

.footer__block {
  float: left;
  width: 25%;
  margin: 30px 0; }
  @media (max-width: 850px) {
    .footer__block {
      display: none; } }
  .footer__block .heading {
    margin-bottom: 20px; }
    .footer__block .heading span {
      border-bottom: 3px solid #ff7e27;
      display: inline-block;
      padding: 0 0 8px 0;
      font-size: 1.15em;
      color: #f5f5f5;
      letter-spacing: 0.08em;
      font-weight: normal; }
  .footer__block .link {
    font-size: .95em;
    padding: 8px 0; }
    .footer__block .link a:hover {
      color: #ff7e27; }
  .footer__block .tag {
    display: inline-block; }
    .footer__block .tag a {
      display: inline-block;
      background-color: #C4C4C4;
      color: #1d1d1b;
      padding: 5px 10px;
      margin: 0 5px 10px 0; }
  .footer__block .iso-img {
    display: inline-block;
    width: 37px;
    padding-right: 10px;
    vertical-align: middle; }

.footer__language {
  display: none; }
  @media (max-width: 850px) {
    .footer__language {
      display: block;
      padding: 15px 0; } }
  .footer__language .language-list {
    text-align: center; }
    .footer__language .language-list li {
      margin: 0 5px;
      background-color: #4e4e4e;
      color: #ccc;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: inline-block;
      line-height: 32px;
      text-align: center;
      font-size: .85em;
      transition: background-color .25s, color .25s; }
      .footer__language .language-list li:hover, .footer__language .language-list li.active {
        background-color: #ff7e27;
        color: #fff; }

.footer__copyright {
  background-color: #ff7e27;
  color: #fff;
  font-size: .85em;
  line-height: 1.3em;
  padding: 10px 0; }
  @media (max-width: 850px) {
    .footer__copyright {
      padding: 10px 0;
      font-size: .8em; } }

@media (min-width: 851px) {
  .level-menu__level-2--wrap, .nav__dropdown {
    opacity: 0;
    pointer-events: none;
    transition: visibility 0s;
    transition-delay: .2s;
    position: fixed;
    top: 85px;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #1d1d1b;
    border-top: 3px solid #ff7e27; }
  .level-1-has-sub:hover .level-menu__level-2--wrap, .nav__dropdown.active {
    opacity: 1;
    pointer-events: auto; }
  .nav {
    width: 100%;
    height: 85px;
    background-color: #1d1d1b;
    position: fixed;
    z-index: 998;
    transition: height .3s; }
    .nav:before, .nav:after {
      content: " ";
      display: table; }
    .nav:after {
      clear: both; }
    .nav .container {
      height: 100%; }
  .nav--small {
    height: 65px; }
    .nav--small .search-btn,
    .nav--small .nav__language {
      transform: translateY(-14px); }
    .nav--small .level-menu__level-2--wrap,
    .nav--small .nav__dropdown {
      top: 65px; }
    .nav--small .nav__level-menu .level-1 {
      line-height: 65px; }
    .nav--small .nav__logo {
      background-size: 80px auto;
      background-position: 0 5px; }
  .nav__logo {
    float: left;
    height: 100%;
    width: 200px;
    background: url("../img/common/logo-white.svg") 0 9px no-repeat;
    background-size: 100px auto;
    transition: background-size .3s; }
    .nav__logo h1, .nav__logo h2 {
      text-indent: -999px; }
    .nav__logo a {
      display: block;
      width: 120px;
      height: 100%; }
  .nav__level-menu {
    float: left;
    height: 100%; }
  .nav__other-link {
    float: right;
    height: 100%;
    padding-top: 26px;
    transition: transform .3s; }
  .nav__level-menu .level-1 {
    display: block;
    padding: 0 20px;
    cursor: pointer;
    transition: color .25s, line-height .3s;
    height: 100%;
    line-height: 85px;
    -webkit-transform: translateZ(0); }
    .nav__level-menu .level-1:hover {
      color: #ff7e27; }
  .nav__level-menu .heading {
    height: 55px;
    font-size: 1.1em; }
  .nav__level-menu .container {
    min-height: 410px; }
  .nav__level-menu .back-heading {
    display: none; }
  .level-menu__level-1 {
    height: 100%;
    color: #eee; }
    .level-menu__level-1 > li {
      font-size: .95em;
      float: left;
      height: 100%; }
  .level-menu__level-2--wrap .level-menu-prev,
  .level-menu__level-2--wrap .level-menu-next {
    position: absolute;
    bottom: 0;
    width: 30px;
    height: 30px;
    border: 1px solid #888;
    color: #aaa;
    text-align: center;
    line-height: 26px;
    cursor: pointer;
    z-index: 99;
    background-color: #1d1d1b;
    transition: background-color .25s;
    margin-bottom: 20px; } }
  @media (min-width: 851px) and (min-width: 1025px) {
    .level-menu__level-2--wrap .level-menu-prev:hover,
    .level-menu__level-2--wrap .level-menu-next:hover {
      background-color: #31312f; } }

@media (min-width: 851px) {
  .level-menu__level-2--wrap .level-menu-prev {
    right: 50px; }
  .level-menu__level-2--wrap .level-menu-next {
    right: 0; }
  .level-menu__level-2--wrap .slick-disabled {
    opacity: .4;
    pointer-events: none; }
  .level-menu__level-3 > div > div {
    border-bottom: 1px solid #343434; }
    .level-menu__level-3 > div > div > a {
      display: block;
      width: 100%;
      padding: 8px 0;
      line-height: 1.3em; }
      .level-menu__level-3 > div > div > a:hover {
        color: #ff7e27; }
  .level-menu__item {
    float: left;
    width: 20%;
    margin: 30px 0;
    padding: 0 12px; }
    .level-menu__item:focus {
      outline: 0; }
  .nav__language {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    position: relative; }
    .nav__language .current {
      background-color: #4e4e4e;
      color: #ccc;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 100%;
      display: inline-block;
      line-height: 42px;
      font-size: 1em;
      text-align: center;
      cursor: pointer;
      transition: background-color .25s, color .25s;
      position: relative;
      z-index: 10; }
      .nav__language .current:hover, .nav__language .current.active {
        background-color: #ff7e27;
        color: #fff; }
    .nav__language .language-list li {
      position: absolute;
      left: 0;
      top: 0;
      visibility: hidden;
      transition: transform .35s;
      transform: translate(5px, 5px); }
      .nav__language .language-list li.active {
        visibility: visible; }
        .nav__language .language-list li.active:first-child {
          transform: translate(-40px, -15px); }
        .nav__language .language-list li.active:nth-child(2) {
          transform: translate(-45px, 23px); }
        .nav__language .language-list li.active:nth-child(3) {
          transform: translate(-25px, 55px); }
        .nav__language .language-list li.active:nth-child(4) {
          transform: translate(10px, 65px); }
        .nav__language .language-list li.active:nth-child(5) {
          transform: translate(45px, 55px); }
      .nav__language .language-list li > a {
        background-color: #4e4e4e;
        color: #ccc;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: inline-block;
        line-height: 32px;
        text-align: center;
        font-size: .85em;
        transition: background-color .25s, color .25s; }
        .nav__language .language-list li > a:hover, .nav__language .language-list li > a.active {
          background-color: #ff7e27;
          color: #fff; }
  .nav__search {
    display: inline-block;
    vertical-align: top; }
    .nav__search .search-btn {
      background-color: #4e4e4e;
      color: #ccc;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 100%;
      display: inline-block;
      line-height: 42px;
      font-size: 1em;
      text-align: center;
      cursor: pointer;
      transition: background-color .25s, color .25s; }
      .nav__search .search-btn:hover, .nav__search .search-btn.active {
        background-color: #ff7e27;
        color: #fff; }
      .nav__search .search-btn.active {
        background-color: #b12c33; }
      .nav__search .search-btn i {
        position: relative;
        top: -3px; }
    .nav__search .search-input {
      margin: 150px 0; }
  .nav__burger-icon,
  .nav__mobile-home {
    display: none; }
  .nav__search-small {
    display: none; } }

.nav__dropdown {
  background-color: rgba(29, 29, 27, 0.9); }

#online-booking {
  display: none; }

[data-lang="zh"] #online-booking {
  display: block; }

@media (max-width: 850px) {
  .nav__level-menu .level-menu__level-1 {
    transition: all .5s ease-in-out; }
  nav {
    background-color: #1d1d1b;
    height: 50px;
    position: fixed;
    z-index: 9999;
    width: 100%;
    user-select: none; }
    nav:before, nav:after {
      content: " ";
      display: table; }
    nav:after {
      clear: both; }
    nav > .container {
      height: 100%;
      position: relative; }
  .nav__logo {
    float: left;
    height: 100%;
    width: 60px;
    background: url("../img/common/logo-white.svg") 0 6px no-repeat;
    background-size: 100%; }
    .nav__logo > a {
      display: block;
      height: 100%; }
    .nav__logo h1, .nav__logo h2 {
      text-indent: -999px; }
  .nav__level-menu {
    display: none;
    background-color: #1d1d1b;
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    border-top: 1px #000 solid; }
    .nav__level-menu .level-menu__level-1 {
      height: 100vh; }
    .nav__level-menu .level-menu__level-2--wrap,
    .nav__level-menu .level-menu__level-3 {
      display: none;
      position: absolute;
      background-color: #1d1d1b;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 100%;
      padding-bottom: 50px; }
      .nav__level-menu .level-menu__level-2--wrap .container,
      .nav__level-menu .level-menu__level-3 .container {
        padding: 0;
        width: 100%; }
    .nav__level-menu .level-inner {
      height: 100%;
      padding-bottom: 60px;
      overflow-y: auto;
      overflow-x: hidden; }
    .nav__level-menu .level-item {
      display: block;
      color: #fff;
      padding: 15px 20px;
      font-size: 1.05em;
      font-weight: 300;
      border-bottom: 1px solid #343434;
      position: relative; }
    .nav__level-menu .heading.level-item:after,
    .nav__level-menu .level-1-has-sub > .level-item:after {
      content: "";
      color: #fff;
      width: 16px;
      height: 16px;
      position: absolute;
      top: auto;
      right: 20px;
      background: url("../img/icons/arrow-right.svg") 0 0 no-repeat;
      background-size: 100% 100%; }
    .nav__level-menu .back-heading {
      color: #999;
      border-bottom: 1px solid #343434;
      padding: 10px 0 10px 40px;
      position: relative; }
      .nav__level-menu .back-heading:before {
        content: "";
        color: #999;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 10px;
        background: url("../img/icons/arrow-left.svg") 0 0 no-repeat;
        background-size: 100% 100%; }
  .nav__other-link {
    display: none; }
  .nav__burger-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 60px;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    color: #ccc; }
    .nav__burger-icon.active {
      background: #000;
      font-size: 16px; }
  .nav__mobile-home {
    display: block;
    position: absolute;
    right: 60px;
    top: 0;
    height: 100%;
    color: #ccc;
    font-size: 20px;
    width: 50px;
    text-align: center;
    line-height: 50px; }
  .nav__search-small {
    display: block; }
  .nav__search {
    display: inline-block;
    vertical-align: top; }
    .nav__search .search-btn {
      background-color: #4e4e4e;
      color: #ccc;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 100%;
      display: inline-block;
      line-height: 42px;
      font-size: 1em;
      text-align: center;
      cursor: pointer;
      transition: background-color .25s, color .25s; }
      .nav__search .search-btn:hover, .nav__search .search-btn.active {
        background-color: #ff7e27;
        color: #fff; }
      .nav__search .search-btn.active {
        background-color: #b12c33; }
      .nav__search .search-btn i {
        position: relative;
        top: -3px; }
    .nav__search .search-input {
      position: relative;
      margin: 150px 0; }
  .mobile-hide {
    display: none; } }

.add-top-gap {
  padding-top: 85px; }
  @media (max-width: 850px) {
    .add-top-gap {
      padding-top: 50px; } }

.page-content {
  padding: 30px 0 40px 0; }
  @media (max-width: 850px) {
    .page-content {
      padding: 22px 0; } }

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 1000; }
  .modal.is-active {
    opacity: 1;
    visibility: visible; }
  .modal.is-active .modal__container {
    transform: translateY(0); }

.modal__heading {
  margin-bottom: 5px; }

.modal__container {
  position: relative;
  width: 560px;
  text-align: center;
  transform: translateY(20px);
  transition: transform 0.3s;
  padding: 40px 20px;
  background-color: white;
  max-height: 80vh;
  overflow-y: auto; }
  @media (max-width: 767px) {
    .modal__container {
      padding: 80px 20px 20px; } }

.modal__close {
  position: absolute;
  padding: 20px;
  color: white;
  background-color: #ff7e27;
  top: 0;
  right: 0; }

.modal__close-icon {
  font-size: 20px;
  transition: color 0.3s; }

.modal-form__submit {
  margin: 0 auto; }

.modal-form {
  margin-top: 40px; }

.sending-cover {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  color: #565756;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s; }
  .sending-cover.is-active {
    opacity: 1;
    visibility: visible; }

.index-heading {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 400; }
  @media (max-width: 550px) {
    .index-heading {
      margin-bottom: 20px; } }

.index-news-block {
  background-color: #f5f5f5;
  padding: 60px 0; }

.index-events-block {
  background-color: #f5f5f5;
  padding: 60px 0; }

.index-page h1 {
  text-indent: -999px;
  height: 0; }

.catalogue-description {
  padding: 22px 0; }
  .catalogue-description:before, .catalogue-description:after {
    content: " ";
    display: table; }
  .catalogue-description:after {
    clear: both; }
  .catalogue-description .col-1 {
    width: 48.57143%;
    float: left;
    margin-right: 2.85714%; }
    @media (max-width: 850px) {
      .catalogue-description .col-1 {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }
  .catalogue-description .col-2 {
    width: 48.57143%;
    float: right;
    margin-right: 0; }
    @media (max-width: 850px) {
      .catalogue-description .col-2 {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        margin-top: 30px; } }
  .catalogue-description .btn-style-01 {
    margin-top: 30px; }
    @media (min-width: 851px) {
      .catalogue-description .btn-style-01 {
        float: right; } }
  .catalogue-description p {
    line-height: 1.8em; }
    html[lang="en"] .catalogue-description p, html[lang="es"] .catalogue-description p {
      line-height: 1.6em; }

.img-card-wrap {
  padding: 60px 0; }
  .img-card-wrap:before, .img-card-wrap:after {
    content: " ";
    display: table; }
  .img-card-wrap:after {
    clear: both; }

.img-card {
  width: 31.42857%;
  float: left;
  margin-right: 2.85714%;
  text-align: center; }
  @media (max-width: 767px) {
    .img-card {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 20px; } }
  .img-card:last-child {
    width: 31.42857%;
    float: right;
    margin-right: 0; }
    @media (max-width: 767px) {
      .img-card:last-child {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }

.img-card__img {
  max-width: 56px;
  margin: 0 auto 15px; }

.img-card__heading {
  font-size: 20px;
  color: #666666; }

.product-description {
  position: relative;
  padding-top: 22px; }
  @media (min-width: 851px) {
    .product-description .btn-style-01 {
      position: absolute;
      top: 22px;
      right: 0; } }
  @media (max-width: 850px) {
    .product-description .btn-style-01 {
      margin-top: 30px; } }
  .product-description .main-table {
    max-width: 860px; }

@media (min-width: 1025px) {
  #contact-fixed.active {
    position: fixed;
    top: 120px; } }

.color-card-wrap {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0; }

.color-card-grid:before, .color-card-grid:after {
  content: " ";
  display: table; }

.color-card-grid:after {
  clear: both; }

.color-card {
  width: 31.42857%;
  float: left;
  margin-right: 2.85714%;
  position: relative;
  height: 108px;
  background-color: #CCCCCC;
  margin-bottom: 20px; }
  .color-card:nth-child(3n) {
    width: 31.42857%;
    float: right;
    margin-right: 0; }
  @media (min-width: 1025px) {
    .color-card:hover .color-card__color-bg {
      opacity: 1; } }
  @media (max-width: 767px) {
    .color-card {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
      .color-card:nth-child(3n) {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }

.color-card__color-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s; }

.color-card__container {
  position: relative;
  height: 100px;
  background-color: #F5F5F5;
  padding: 20px; }
  .color-card__container h3 {
    font-weight: bold;
    font-size: 20px; }
  .color-card__container p {
    font-size: 20px; }

.color-card__title {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 45px); }

.color-card__circle {
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 40px;
  border-radius: 50%; }

.color-card-note {
  text-align: right;
  font-size: 14px;
  color: #666666; }
  @media (max-width: 767px) {
    .color-card-note {
      text-align: left; } }

.small-wrap {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0; }

.bullet-list:before, .bullet-list:after {
  content: " ";
  display: table; }

.bullet-list:after {
  clear: both; }

.bullet-list__item {
  margin-bottom: 20px;
  font-size: 20px;
  width: 48.57143%;
  float: left;
  margin-right: 2.85714%; }
  .bullet-list__item:nth-child(2n) {
    width: 48.57143%;
    float: right;
    margin-right: 0; }
  .bullet-list__item:before {
    display: inline-block;
    content: "•";
    color: #ff7e27;
    margin-right: 13px;
    transform: translateY(2px); }
  @media (max-width: 767px) {
    .bullet-list__item {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
      .bullet-list__item:nth-child(2n) {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }

.about-main {
  padding: 22px 0; }
  .about-main:before, .about-main:after {
    content: " ";
    display: table; }
  .about-main:after {
    clear: both; }
  @media (max-width: 850px) {
    .about-main {
      padding: 12px 0; } }
  .about-main .col-1 {
    width: 31.42857%;
    float: left;
    margin-right: 2.85714%; }
    @media (max-width: 850px) {
      .about-main .col-1 {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }
  .about-main .col-2 {
    width: 65.71429%;
    float: right;
    margin-right: 0; }
    @media (max-width: 850px) {
      .about-main .col-2 {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        margin-top: 30px; } }
  .about-main .heading {
    margin-bottom: 30px;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.3em; }
  @media (max-width: 850px) {
    .about-main .crop-img {
      height: 300px;
      overflow: hidden;
      position: relative; } }
  @media (max-width: 550px) {
    .about-main .crop-img {
      height: 200px; } }
  @media (max-width: 850px) {
    .about-main .crop-img img {
      position: absolute;
      top: -50%; } }

.search-page .page-content .search-input input[type="text"] {
  background-color: #f5f5f5;
  color: #565756; }

.search-blcok {
  padding: 22px 0;
  position: relative; }
  .search-blcok .heading,
  .search-blcok .three-search-bar {
    display: inline-block;
    vertical-align: middle; }
  .search-blcok .heading {
    color: #ff7e27;
    margin-right: 10px;
    font-size: 1.35em;
    width: 150px; }
    @media (max-width: 1024px) {
      .search-blcok .heading {
        display: block;
        margin-bottom: 10px; } }
    @media (max-width: 850px) {
      .search-blcok .heading {
        margin-bottom: 0; } }
  .search-blcok .three-search-bar {
    width: 765px; }
    @media (max-width: 1024px) {
      .search-blcok .three-search-bar {
        width: 100%; } }
    .search-blcok .three-search-bar .dropdown {
      margin-right: 15px; }
      @media (min-width: 851px) {
        .search-blcok .three-search-bar .dropdown:last-child {
          margin-right: 0; } }
      @media (max-width: 850px) {
        .search-blcok .three-search-bar .dropdown {
          max-width: 23%;
          margin-top: 10px; } }
      @media (max-width: 550px) {
        .search-blcok .three-search-bar .dropdown {
          max-width: 250px;
          display: block;
          margin: 10px auto; } }
  .search-blcok #filter-button {
    position: absolute;
    right: 0;
    top: 18px; }
    @media (max-width: 1024px) {
      .search-blcok #filter-button {
        position: static;
        margin: 20px 0 0 0; } }
    @media (max-width: 550px) {
      .search-blcok #filter-button {
        margin: 10px auto 0 auto; } }

.search-result {
  margin-top: 20px; }
  @media (min-width: 1025px) {
    .search-result {
      min-height: 40vh; } }
  @media (max-width: 1024px) {
    .search-result {
      min-height: 60vh; } }

.search-no-result {
  margin-top: 20px;
  min-height: 400px; }
  @media (min-width: 851px) {
    .search-no-result {
      min-height: 40vh; } }

#filter-result:before, #filter-result:after,
#search-result:before,
#search-result:after {
  content: " ";
  display: table; }

#filter-result:after,
#search-result:after {
  clear: both; }

.filter-result__item,
.search-result__item {
  height: 230px;
  line-height: 230px;
  overflow: hidden;
  text-align: center;
  margin-bottom: 2.85714%;
  border: 1px solid #ccc;
  transition: border-color .4s;
  background-color: #f5f5f5; }
  @media (min-width: 851px) {
    .filter-result__item,
    .search-result__item {
      width: 22.85714%;
      float: left;
      margin-right: 2.85714%; }
      .filter-result__item:nth-child(4n),
      .search-result__item:nth-child(4n) {
        width: 22.85714%;
        float: right;
        margin-right: 0; } }
  @media (max-width: 850px) {
    .filter-result__item,
    .search-result__item {
      width: 31.42857%;
      float: left;
      margin-right: 2.85714%;
      height: 100px; }
      .filter-result__item:nth-child(3n),
      .search-result__item:nth-child(3n) {
        width: 31.42857%;
        float: right;
        margin-right: 0; } }
  @media (max-width: 550px) {
    .filter-result__item,
    .search-result__item {
      width: 48.57143%;
      float: left;
      margin-right: 2.85714%; }
      .filter-result__item:nth-child(3n),
      .search-result__item:nth-child(3n) {
        width: 48.57143%;
        float: left;
        margin-right: 2.85714%; }
      .filter-result__item:nth-child(2n),
      .search-result__item:nth-child(2n) {
        width: 48.57143%;
        float: right;
        margin-right: 0; } }
  @media (min-width: 1025px) {
    .filter-result__item,
    .search-result__item {
      background-size: 200% 100%;
      background-image: linear-gradient(to right, transparent 50%, #fff 50%);
      transition: background-position 0.3s, cubic-bezier(0.19, 1, 0.22, 1) 0.3s, background-color 0.5s ease; }
      .filter-result__item:hover,
      .search-result__item:hover {
        background-color: #fff;
        background-position: -100% 100%;
        border-color: #ff7e27; }
        .filter-result__item:hover .heading,
        .search-result__item:hover .heading {
          color: #ff7e27; } }
  .filter-result__item > a,
  .search-result__item > a {
    display: block; }
    @media (max-width: 850px) {
      .filter-result__item > a,
      .search-result__item > a {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center; } }
  .filter-result__item .heading,
  .search-result__item .heading {
    line-height: 1.3em;
    display: inline-block;
    font-size: 1.3em;
    color: #777;
    transition: color .3s;
    margin-right: 0;
    padding: 20px; }
    @media (max-width: 850px) {
      .filter-result__item .heading,
      .search-result__item .heading {
        font-size: 1.1em;
        line-height: 1.3em; } }
    @media (max-width: 550px) {
      .filter-result__item .heading,
      .search-result__item .heading {
        font-size: 1em; } }

#filter-no-result,
.search-no-result {
  width: 100%;
  height: 100%;
  color: #6c6e70;
  font-size: 1.1em;
  text-align: center;
  background-color: #f5f5f5;
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; }

#filter-result-loader {
  width: 30px;
  height: 30px;
  background: url("../img/common/loader.gif") 0 0 no-repeat;
  margin: 0 auto; }

.full-map .map {
  height: 400px; }
  @media (max-width: 850px) {
    .full-map .map {
      height: 300px; } }

.contact-main {
  margin: 40px auto; }
  .contact-main:before, .contact-main:after {
    content: " ";
    display: table; }
  .contact-main:after {
    clear: both; }
  .contact-main .col-1 {
    width: 31.42857%;
    float: left;
    margin-right: 2.85714%; }
    @media (max-width: 850px) {
      .contact-main .col-1 {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0; } }
  .contact-main .col-2 {
    width: 65.71429%;
    float: right;
    margin-right: 0; }
    @media (max-width: 850px) {
      .contact-main .col-2 {
        width: 100%;
        float: left;
        margin-left: 0;
        margin-right: 0;
        margin-top: 30px; } }
  .contact-main .heading {
    margin-bottom: 30px; }
    @media (max-width: 850px) {
      .contact-main .heading {
        margin-bottom: 10px; } }
  .contact-main__list > li {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    display: table;
    width: 100%; }
  .contact-main__list [class*="icon-"] {
    display: table-cell;
    text-align: left;
    width: 40px;
    color: #b12c33;
    font-size: 20px; }
  .contact-main__list .text {
    display: table-cell;
    word-break: break-word; }
  .contact-main__list .icon-mail {
    font-size: 14px; }

.contact-location hr {
  margin: 40px 0; }

.global-location {
  margin-top: 40px; }
  .global-location:before, .global-location:after {
    content: " ";
    display: table; }
  .global-location:after {
    clear: both; }
  .global-location__item {
    float: left;
    width: 50%;
    padding-right: 40px;
    margin-bottom: 40px; }
    .global-location__item:nth-child(2n) {
      padding-right: 0;
      float: right; }
      @media (max-width: 850px) {
        .global-location__item:nth-child(2n) {
          width: 100%;
          float: none; } }
    @media (min-width: 851px) {
      .global-location__item {
        height: 220px;
        overflow: hidden; } }
    @media (max-width: 850px) {
      .global-location__item {
        width: 100%;
        float: none;
        padding-right: 0; } }
  .global-location .contact-main__list > li {
    border: 0;
    padding: 5px; }
  .global-location .contact-main__list [class*="icon-"] {
    font-size: 16px;
    width: 27px; }
  .global-location .contact-main__list .icon-mail {
    font-size: 12px; }
  .global-location .contact-main__list .text {
    line-height: 1.3em; }
  .global-location .country {
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.3em;
    color: #b12c33;
    margin-bottom: 10px; }
  .global-location .company {
    font-weight: bold;
    font-size: 1.1em;
    color: #1d1d1b;
    margin-bottom: 10px; }

@media (min-width: 851px) {
  html[lang="en"] .global-location__item,
  html[lang="es"] .global-location__item,
  html[lang="ja"] .global-location__item {
    height: 265px;
    overflow: hidden; } }

.form-message__success, .form-message__error {
  padding: 10px 20px;
  margin-bottom: 20px; }
  .form-message__success i, .form-message__error i {
    font-size: 32px;
    padding-right: 10px; }

.form-message__success {
  background-color: #EAF9DA;
  border: 1px solid #81C305;
  border-radius: 4px; }
  .form-message__success i {
    color: #81C305; }

.form-message__error {
  background-color: #FFE4E4;
  border: 1px solid #DA4E4E;
  border-radius: 4px; }
  .form-message__error i {
    color: #DA4E4E; }

.main-article,
.newest-article {
  background-color: #fff;
  padding: 34px; }
  @media (max-width: 850px) {
    .main-article,
    .newest-article {
      padding: 24px; } }

.col-8 {
  width: 65.71429%;
  float: left;
  margin-right: 2.85714%; }
  @media (max-width: 850px) {
    .col-8 {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

.col-4 {
  width: 31.42857%;
  float: right;
  margin-right: 0; }
  @media (max-width: 850px) {
    .col-4 {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px; } }

.main-article__content {
  margin: 15px 0; }
  .main-article__content img {
    display: block;
    margin: 15px auto;
    width: auto;
    max-width: 100%; }
  .main-article__content p strong {
    font-weight: 600;
    color: #444; }
  .main-article__content a {
    color: #1d70b7;
    transition: color .3s;
    text-decoration: underline; }
    .main-article__content a:hover {
      color: #3b8ed5; }
  .main-article__content ul {
    margin-left: 15px; }
    .main-article__content ul > li {
      list-style: disc; }
  .main-article__content ol {
    margin-left: 15px; }
    .main-article__content ol > li {
      list-style: decimal; }
  .main-article__content h3 {
    font-size: 1.8em;
    line-height: 1.3em;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 10px; }
  .main-article__content h1, .main-article__content h2, .main-article__content h4, .main-article__content h5, .main-article__content h6 {
    font-size: 1em; }

.main-article__heading {
  color: #ff7e27;
  padding-bottom: 15px; }
  .main-article__heading h1 {
    font-size: 2.25em;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.3em; }
  @media (max-width: 550px) {
    .main-article__heading h1, .main-article__heading h2 {
      font-size: 1.3em; } }

.main-article__contact {
  margin-top: 40px; }

.main-article .orange-hr {
  margin-bottom: 15px; }

.main-article .date {
  margin-bottom: 10px; }
  @media (max-width: 850px) {
    .main-article .date {
      font-size: .9em; } }

.main-article .author {
  font-style: italic;
  font-size: .95em;
  letter-spacing: 0.05em;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 2em; }

.main-article__control {
  margin-top: 30px; }
  .main-article__control:before, .main-article__control:after {
    content: " ";
    display: table; }
  .main-article__control:after {
    clear: both; }
  .main-article__control i {
    margin-top: -5px;
    transition: transform .3s; }
  .main-article__control span {
    display: inline-block;
    padding: 0 10px; }
  .main-article__control .prev, .main-article__control .next {
    color: #b12c33; }
  .main-article__control .prev {
    float: left; }
    .main-article__control .prev:hover i {
      transform: translateX(-10px); }
  .main-article__control .next {
    float: right; }
    .main-article__control .next:hover i {
      transform: translateX(10px); }

.main-article .event-data-list {
  color: #b12c33;
  font-size: 1.05em;
  margin-left: -5px; }

.newest-article .heading {
  color: #b12c33; }

.newest-article__item {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-top: 20px; }
  .newest-article__item:last-child {
    border-bottom: 0;
    padding-bottom: 0; }
  @media (max-width: 850px) {
    .newest-article__item:before, .newest-article__item:after {
      content: " ";
      display: table; }
    .newest-article__item:after {
      clear: both; } }
  .newest-article__item:hover figcaption {
    color: #ff7e27; }
  .newest-article__item figcaption {
    margin-top: 10px;
    font-size: 1.05em;
    line-height: 1.3em;
    color: #6c6e70; }
    @media (max-width: 850px) {
      .newest-article__item figcaption {
        float: left;
        width: 70%;
        margin-top: 0; } }
    @media (max-width: 550px) {
      .newest-article__item figcaption {
        width: 60%; } }
  @media (max-width: 850px) {
    .newest-article__item img {
      float: left;
      width: 20%;
      margin-right: 15px; } }
  @media (max-width: 550px) {
    .newest-article__item img {
      width: 30%; } }

.sitemap-wrapper {
  padding-top: 22px; }
  .sitemap-wrapper > .heading {
    margin-bottom: 20px; }
  .sitemap-wrapper hr {
    margin: 40px 0; }

.sitemap__level1 > .heading {
  font-size: 1.4em;
  color: #1d1d1b;
  margin-bottom: 15px; }

.sitemap__level2 {
  margin-left: 15px;
  margin-bottom: 20px; }
  .sitemap__level2 > .heading {
    font-size: 1.25em;
    font-weight: normal;
    color: #6c6e70;
    margin-bottom: 10px; }

.sitemap__level3 {
  border-left: 1px solid #ff7e27;
  margin-left: 5px;
  padding-left: 15px;
  padding-bottom: 25px; }
  .sitemap__level3 > .heading {
    font-size: 1.1em;
    color: #565756; }
  .sitemap__level3 .level3__item > li {
    display: inline-block;
    color: #808284;
    margin-top: 10px;
    margin-right: 15px; }
  .sitemap__level3 .level3__item a:hover {
    color: #ff7e27; }

.not-found {
  min-height: 70vh;
  text-align: center; }
  .not-found .heading {
    padding-top: 50px; }
  .not-found .back-home {
    padding-top: 50px;
    color: #b12c33; }
    .not-found .back-home:hover {
      color: #1d1d1b; }
    .not-found .back-home i {
      padding-right: 10px;
      margin-top: -4px; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100%; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  height: 100%; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }
