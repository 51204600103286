.body-loader {
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #222;
	z-index: 999;
	transition: opacity .3s, visibility .3s;
	&.is-active {
		opacity: 1;
		visibility: visible;
	}
}

.body-loader__inner {
	width: 130px;
	margin: 0 auto;
	color: #fff;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -65px;
	margin-top: -65px;
}

.body-loader__logo {
	width: 100%;
}

.loading-animation {
	width: 80px;
	margin: 20px auto;
	@include clfix;
	li {
		height: 0;
		display: block;
		float: left;
		margin: 0 8px;
		height: 10px;
		width: 10px;
		border: 3px solid #fff;
		border-radius: 100%;
		transform: transformZ(0);
		animation: LOADING 2s infinite;
		background-color: #fff;
		&:first-child {
			animation-delay: 0s;
		}
		&:nth-child(2n) {
			animation-delay: 0.2s;
		}
		&:nth-child(3n) {
			animation-delay: 0.4s;
		}
	}
}

@keyframes  LOADING {
  0%   { 
    transform: scale(0.5);
  }
  50%   { 
    transform: scale(1);
  }
  100% { 
    transform: scale(0.5);
  }
}

@keyframes  LOADINGREV {
  0%   { 
    transform: scale(0.5);
  }
  50%   { 
    transform: scale(1);
  }
  100% { 
    transform: scale(0.5);
  }
}
