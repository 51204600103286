[class*="heading"] {
	font-weight: $weight-b;
	line-height: 1.3em;
	a {
		@include breakpoint(above-desktop) {
			&:hover {
			color: $main-color;
			}
		}
	}
}

%p-style {
	font-size: 1.05em;
	line-height: 1.5em;
}

%product-heading {
	font-size: 2em;
	color: $main-color;
	margin-bottom: 10px;
}

a {
	transition: color .3s;
}

h2 {
	font-size: 2.25em;
	font-weight: $weight-b;
	letter-spacing: 1px;
	line-height: 1.3em;
	@include breakpoint(below-small) {
		font-size: 2em;
	}
}

h3 {
	font-size: 1.5em;
	line-height: 1.2em;
	@include breakpoint(below-small) {
		font-size: 1.3em;
	}
}

p {
	@extend %p-style;
}

.orange-hr {
	background-color: $orange-light;
	height: 3px;
}

.disc-list {
	padding-left: 1.35em;
	> li {
		list-style: disc;
		margin: 5px 0;
		@extend %p-style;
	}
}

.highlight {
	color: $main-color;
}

////////end global//////
%gray-style {
	font-size: 1em;
	color: #999;
	line-height: 1.3em;
}

%news-list-heading {
	margin: 10px 0;
	transition: color .3s;
	@include ellipsis(2, 2.5em);
	@include breakpoint(below-small) {
		@include ellipsis(3, 3.8em);
	}
}
%news-list-description {
	margin-bottom: 10px;
	@include ellipsis(4, 6em); //line-height = 1.5em
	@include breakpoint(below-desktop) {
		@include ellipsis(3, 4.5em);
	}
	@include breakpoint(below-small) {
		display: none;
	}
}


///// icon ///////
.icon-location,
.icon-time {
	font-size: 18px;
	width: 18px;
	text-align: center;
	margin-right: 5px;
}
