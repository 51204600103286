#index-banner {
	text-align: right;
	.container {
		height: 100%;
	}
	.table {
		width: 50%;
		@include breakpoint(below-medium) {
			width: 60%;
		}
		@include breakpoint(below-small) {
			width: 70%;
		}
		@include breakpoint(below-mobile) {
			width: 100%;
		}
	}
	.table-cell {

	}
	.heading {
		font-size: 3.8em;
		color: $orange;
		font-weight: $weight-bb;
		line-height: 1.1em;
		@include breakpoint(below-desktop) {
			font-size: 3.2em;
		}
		@include breakpoint(below-small) {
			font-size: 2.2em;
		}
		@include breakpoint(below-mobile) {
			font-size: 1.8em;
		}
	}
	.sub-heading {
		font-size: 2.2em;
		color: #fff;
		letter-spacing: .5em;
		margin-right: -.5em;
		margin-top: 10px;
		font-weight: $weight-b;
		@include breakpoint(below-small) {
			font-size: 1.8em;
		}
		@include breakpoint(below-mobile) {
			font-size: 1.1em;
		}
	}
	.paragraph--white,
	.paragraph--gray {
		letter-spacing: .05em;
		margin-top: 10px;
		font-size: 1.4em;
		line-height: 1.3em;
		@include breakpoint(below-small) {
			font-size: 1em;
		}
		@include breakpoint(below-small) {
			font-size: .9em;
		}
	}
	.paragraph--white {
		color: #fff;
	}
	.paragraph--gray {
		color: $gray;
	}
}

.bg-carousel__title {
	text-align: left;
	display: inline-block;
	position: absolute;
	top: 20%;
	right: 0;
	background-color: rgba(255, 255, 255, 0.8);
	padding: 40px 80px 40px 40px;
	@include breakpoint(below-tablet) {
		top: auto;
		bottom: 0;
		padding: 10px;
	}
	p {
		letter-spacing: .05em;
		margin-top: 10px;
		font-size: 1.4em;
		line-height: 1.3em;
		@include breakpoint(below-tablet) {
			font-size: 1em;
		}
	}
	h2 {
		color: $orange;
		@include breakpoint(below-tablet) {
			font-size: 1.5em;
		}
	}
}

.left-bottom {
	.table-cell {
		vertical-align: bottom;
		padding-right: 30px;
		padding-bottom: 90px;
		@include breakpoint(below-small) {
			padding-bottom: 30px;
		}
		@include breakpoint(below-mobile) {
			padding-bottom: 20px;
		}
	}
}

.left-middle {
	.table-cell {
		vertical-align: middle;
		padding-bottom: 0;
		@include breakpoint(below-small) {
			padding-right: 15px;
		}
	}
}

.right-middle {
	.table {
		float: right;
	}
	.table-cell {
		@include breakpoint(above-desktop) {
			vertical-align: middle;
			padding-bottom: 0;
			text-align: right;
			padding-right: 50px;
		}
		@include breakpoint(below-desktop) {
			padding-right: 30px;
		}
		@include breakpoint(below-mobile) {
			padding-right: 15px;
		}
	}
}

.home-kv-text--white,
.home-kv-text--gray,
.en-home-kv-text--gray,
.en-home-kv-text--white {
	display: inline-block;
	height: 105px;
	width: 550px;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: 0 0;
	margin-bottom: 20px;
	@include breakpoint(below-desktop) {
		width: 430px;
		height: 80px;
		margin-bottom: 15px;
	}
	@include breakpoint(below-small) {
		width: 380px;
		margin-bottom: 0px;
	}
	@include breakpoint(below-mobile) {
		width: 280px;
		height: 55px;
	}
}

.en-home-kv-text--gray,
.en-home-kv-text--white {
	height: 65px;
	@include breakpoint(below-desktop) {
		width: 430px;
		height: 48px;
		margin-bottom: 15px;
	}
	@include breakpoint(below-small) {
		width: 380px;
		height: 47px;
		margin-bottom: 0px;
	}
	@include breakpoint(below-mobile) {
		width: 280px;
		height: 32px;
	}
}

.home-kv-text--gray {
	background-image: url("../img/common/everlight-banner-gray.svg");
}

.home-kv-text--white {
	background-image: url("../img/common/everlight-banner-white.svg");
}

.en-home-kv-text--gray {
	background-image: url("../img/common/en-everlight-banner-gray.svg");
}

.en-home-kv-text--white {
	background-image: url("../img/common/en-everlight-banner-white.svg");
}

#kv-1 {
	background-image: url('/themes/img/banner-01.jpg');
	@include breakpoint(below-mobile) {
		background-image: url('/themes/img/banner-01-s.jpg');
	}
}

#kv-2 {
	background-image: url('/themes/img/banner-02.jpg');
	@include breakpoint(below-mobile) {
		background-image: url('/themes/img/banner-02-s.jpg');
	}
}

#kv-3 {
	background-image: url('/themes/img/banner-03.jpg');
	@include breakpoint(below-mobile) {
		background-image: url('/themes/img/banner-03-s.jpg');
	}
}

#kv-4 {
	background-image: url('/themes/img/banner-04.jpg');
	@include breakpoint(below-mobile) {
		background-image: url('/themes/img/banner-04-s.jpg');
	}
}

#kv-5 {
	background-image: url('/themes/img/banner-05.jpg');
	@include breakpoint(below-mobile) {
		background-image: url('/themes/img/banner-05-s.jpg');
	}
}
