.tabs {
	text-align: right;
	margin-bottom: $content-padding - 5;
	@include breakpoint(below-small) {
		margin-bottom: $content-padding-s;
	}
	.tabs-item {
		display: inline-block;
		color: $gray;
		&:hover {
			color: $orange;
		}
		a {
			display: inline-block;
			padding: 5px;
		}	
	}
	.active {
		color: $orange;
		pointer-events: none;
	}
}