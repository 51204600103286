.full-map {
	.map {
		height: 400px;
		@include breakpoint(below-small) {
			height: 300px;
		}
	}
}

.contact-main {
	@include clfix;
	margin: 40px auto;
	.col-1 {
		@include span(4 of 12);
		@include breakpoint(below-small) {
			@include span(12);
		}
	}
	.col-2 {
		@include span(8 of 12 last);
		@include breakpoint(below-small) {
			@include span(12);
			margin-top: 30px;
		}
	}
	.heading {
		margin-bottom: 30px;
		@include breakpoint(below-small) {
			margin-bottom: 10px;
		}
	}
	&__list {
		> li {
			border-bottom: 1px solid #ccc;
			padding: 10px 0;
			display: table;
			width: 100%;
		}
		[class*="icon-"] {
			display: table-cell;
			text-align: left;
			width: 40px;
			color: $red;
			font-size: 20px;
		}
		.text {
			display: table-cell;
			word-break: break-word;
		}
		.icon-mail {
			font-size: 14px;
		}
	}
}

.contact-location {
	hr {
		margin: 40px 0;
	}
}

.global-location {
	@include clfix;
	margin-top: 40px;
	&__item {
		float: left;
		width: 50%;
		padding-right: 40px;
		margin-bottom: 40px;
		&:nth-child(2n) {
			padding-right: 0;
			float: right;
			@include breakpoint(below-small) {
				width: 100%;
				float: none;
			}
		}
		@include breakpoint(above-small) {
			height: 220px;
			overflow: hidden;
		}
		@include breakpoint(below-small) {
			width: 100%;
			float: none;
			padding-right: 0;
		}
	}
	.contact-main__list {
		> li {
			border: 0;
			padding: 5px;
		}
		[class*="icon-"] {
			font-size: 16px;
			width: 27px;
		}
		.icon-mail {
			font-size: 12px;

		}
		.text {
			line-height: 1.3em;
		}
	}
	.country {
		font-weight: $weight-b;
		font-size: 1.25em;
		line-height: 1.3em;
		color: $red;
		margin-bottom: 10px;
	}
	.company {
		font-weight: $weight-b;
		font-size: 1.1em;
		color: $black;
		margin-bottom: 10px;
	}
}

html[lang="en"],
html[lang="es"],
html[lang="ja"] {
	.global-location__item {
		@include breakpoint(above-small) {
			height: 265px;
			overflow: hidden;
		}
	}
}

.form-message {
	&__success,
	&__error {
		padding: 10px 20px;
		margin-bottom: 20px;
		i {
			font-size: 32px;
			padding-right: 10px;
		}
	}
	&__success {
		background-color: #EAF9DA;
		border: 1px solid #81C305;
		border-radius: 4px;
		i {
			color: #81C305;
		}
	}
	&__error {
		background-color: #FFE4E4;
		border: 1px solid $warning;
		border-radius: 4px;
		i {
			color: $warning;
		}
	}
}
