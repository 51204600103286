.about-main {
	@include clfix;
	padding: $content-padding 0;
	@include breakpoint(below-small) {
		padding: $content-padding-s 0;
	}
	.col-1 {
		@include span(4 of 12);
		@include breakpoint(below-small) {
			@include span(12);
		}
	}
	.col-2 {
		@include span(8 of 12 last);
		@include breakpoint(below-small) {
			@include span(12);
			margin-top: 30px;
		}
	}
	.heading {
		margin-bottom: 30px;
		font-size: 1.5em;
		font-weight: 700;
		line-height: 1.3em;
	}
	.crop-img {
		@include breakpoint(below-small) {
			height: 300px;
			overflow: hidden;
			position: relative;
		}
		@include breakpoint(below-mobile) {
			height: 200px;
		}
		img {
			@include breakpoint(below-small) {
				position: absolute;
				top: -50%;
			}
		}
	}
}
