.sitemap-wrapper {
	padding-top: 22px;
	> .heading {
		@extend %product-heading;
		margin-bottom: 20px;
	}
	hr {
		margin: 40px 0;
	}
}

.sitemap__level1 {
	> .heading {
		font-size: 1.4em;
		color: $black;
		margin-bottom: 15px;
	}
}

.sitemap__level2 {
	margin-left: 15px;
	margin-bottom: 20px;
	> .heading {
		font-size: 1.25em;
		font-weight: normal;
		color: $gray;
		margin-bottom: 10px;
	}
}

.sitemap__level3 {
	border-left: 1px solid $orange;
	margin-left: 5px;
	padding-left: 15px;
	padding-bottom: 25px;
	> .heading {
		font-size: 1.1em;
		color: $gray-dark;
	}
	.level3__item {
		> li {
			display: inline-block;
			color: $gray + 20;
			margin-top: 10px;
			margin-right: 15px;
		}
		a {
			&:hover {
				color: $orange;
			}
		}
	}
}
