.index-heading {
	text-align: center;
	margin-bottom: 30px;
	font-weight: 400;
	@include breakpoint(below-mobile) {
		margin-bottom: 20px;
	}
}

.index-news-block {
	background-color: $white-light;
	padding: $block-padding 0;
}

.index-events-block {
	background-color: $white-light;
	padding: $block-padding 0;
}
.index-page {
	h1 {
		text-indent: -999px;
		height: 0;
	}
}