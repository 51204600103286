@include breakpoint(below-small) {
	%nav-transition {
		transition: all .5s ease-in-out;
	}

	nav {
		background-color: $black;
		height: $nav-s-height;
		position: fixed;
		z-index: 9999;
		width: 100%;
		user-select: none;
		@include clfix;
		> .container {
			height: 100%;
			position: relative;
		}
	}

	.nav__logo {
		float: left;
		> a {
			display: block;
			height: 100%;
		}
		h1,h2 {
			text-indent: -999px;
		}
		height: 100%;
		width: 60px;
		background: url('../img/common/logo-white.svg') 0 6px no-repeat;
		background-size: 100%;
	}

	.nav__level-menu {
		display: none;
		background-color: $black;
		position: fixed;
		top: $nav-s-height;
		left: 0;
		width: 100%;
		border-top: 1px #000 solid;
		.level-menu__level-1 {
			height: 100vh;
			@extend %nav-transition;
		}

		.level-menu__level-2--wrap,
		.level-menu__level-3 {
			display: none;
			position: absolute;
			background-color: $black;
			top: 0;
			bottom: 0;
			left: 100%;
			width: 100%;
			padding-bottom: 50px;
			.container {
				padding: 0;
				width: 100%;
			}
		}

		.level-inner {
			height: 100%;
			padding-bottom: 60px;
			overflow-y: auto;
			overflow-x: hidden;
		}

		.level-item {
			display: block;
			color: #fff;
			padding: 15px 20px;
			font-size: 1.05em;
			font-weight: $weight-l;
			border-bottom: 1px solid $dark-line-color;
			position: relative;
		}

		.heading.level-item,
		.level-1-has-sub > .level-item {
			&:after {
				content: "";
				color: #fff;
				width: 16px;
				height: 16px;
				position: absolute;
				top: auto;
				right: 20px;
				background: url('../img/icons/arrow-right.svg') 0 0 no-repeat;
				background-size: 100% 100%;
			}
		}

		.back-heading {
			color: #999;
			border-bottom: 1px solid $dark-line-color;
			padding: 10px 0 10px 40px;
			position: relative;
			&:before {
				content: "";
				color: #999;
				width: 16px;
				height: 16px;
				position: absolute;
				top: 12px;
				left: 10px;
				background: url('../img/icons/arrow-left.svg') 0 0 no-repeat;
				background-size: 100% 100%;
			}
		}
	}

	.nav__other-link {
		display: none;
	}

	.nav__burger-icon {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 60px;
		font-size: 16px;
		text-align: center;
		line-height: $nav-s-height;
		color: #ccc;
		&.active {
			background: #000;
			font-size: 16px;
		}
	}

	.nav__mobile-home {
		display: block;
		position: absolute;
		right: 60px;
		top: 0;
		height: 100%;
		color: #ccc;
		font-size: 20px;
		width: 50px;
		text-align: center;
		line-height: $nav-s-height;
	}

	.nav__search-small {
		display: block;
	}

	.nav__search {
		display: inline-block;
		vertical-align: top;
		.search-btn {
			@include circle-style;
			&.active {
				background-color: $red;
			}
			i {
				position: relative;
				top: -3px;
			}
		}
		.search-input {
			position: relative;
			margin: 150px 0;
		}
	}


	.mobile-hide {
		display: none;
	}

} //end below-desktop
