.catalogue-description {
	@include clfix;
	padding: $content-padding 0;
	.col-1 {
		@include span(6 of 12);
		@include breakpoint(below-small) {
			@include span(12);
		}
	}
	.col-2 {
		@include span(6 of 12 last);
		@include breakpoint(below-small) {
			@include span(12);
			margin-top: 30px;
		}
	}

	

	.heading {
		@extend %product-heading;
	}
	.btn-style-01 {
		@include breakpoint(above-small) {
			float: right;
		}
		margin-top: 30px;
	}
	p {
		line-height: 1.8em;
		html[lang="en"] & , html[lang="es"] & {
			line-height: 1.6em;
		}
	}
}

.img-card-wrap {
	@include clfix;
	padding: 60px 0;
}

.img-card {
	@include span(4 of 12);
	@include breakpoint(below-tablet) {
		@include span(12);
		margin-bottom: 20px;
	}
	text-align: center;
	&:last-child {
		@include span(4 of 12 last);
		@include breakpoint(below-tablet) {
			@include span(12);
		}
	} 
}

.img-card__img {
	max-width: 56px;
	margin: 0 auto 15px;
}

.img-card__heading {
	font-size: 20px;
	color: #666666;
}

.product-description {
	position: relative;
	padding-top: $content-padding;
	.heading {
		@extend %product-heading;
	}
	.btn-style-01 {
		@include breakpoint(above-small) {
			position: absolute;
			top: $content-padding;
			right: 0;
		}
		@include breakpoint(below-small) {
			margin-top: 30px;
		}
	}
	.main-table {
		max-width: 860px;
	}
}

#contact-fixed.active {
	@include breakpoint(above-desktop) {
		position: fixed;
		top: 120px;
	}
}

.color-card-wrap {
	
	max-width: 900px;
	margin: 0 auto;
	padding: 40px 0;
}

.color-card-grid {
	@include clfix;
}

.color-card {
	@include span(4 of 12);
	position: relative;
	height: 108px;
	background-color: #CCCCCC;
	margin-bottom: 20px;
	&:nth-child(3n) {
		@include span(4 of 12 last);
	}
	@include breakpoint(above-desktop) {
		&:hover {
			.color-card__color-bg {
				opacity: 1;
			}
		}
	}
	@include breakpoint(below-tablet) {
		@include span(12 of 12);
		&:nth-child(3n) {
			@include span(12 of 12);
		}
	}
}

.color-card__color-bg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: opacity 0.3s;
}

.color-card__container {
	position: relative;
	height: 100px;
	background-color: #F5F5F5;
	padding: 20px;
	h3 {
		font-weight: $weight-b;
		font-size: 20px;
	}
	p {
		font-size: 20px;
	}
}

.color-card__title {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 45px);
}

.color-card__circle {
	display: inline-block;
	vertical-align: top;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.color-card-note {
	text-align: right;
	font-size: 14px;
	color: #666666;
	@include breakpoint(below-tablet) {
		text-align: left;
	}
}

.small-wrap {
	max-width: 900px;
    margin: 0 auto;
    padding: 40px 0;
}

.bullet-list {
	@include clfix;
}

.bullet-list__item {
	margin-bottom: 20px;
	font-size: 20px;
	@include span(6 of 12);
	&:nth-child(2n) {
		@include span(6 of 12 last);
	}
	&:before {
		display: inline-block;
		content: "•";
		color: $orange;
		margin-right: 13px;
		transform: translateY(2px);
	}
	@include breakpoint(below-tablet) {
		@include span(12 of 12);
		&:nth-child(2n) {
			@include span(12 of 12);
		}
	}
}


