.pagination {
	&__list {
		display: table;
		margin: 20px auto 0 auto;
		@include clfix;
		> li {
			float: left;
			border: 1px solid $red;
			border-right: 0;
			font-size: 1em;
			background-color: #fff;
			cursor: pointer;
			&:last-child {
				border-right: 1px solid $red;
			}
			&:hover {
				background-color: #eee;
			}
			a {
				display: block;
				width: 100%;
				height: 100%;
				padding: 7px 10px;
				color: $red;
			}
			i {
				font-size: 12px;
				margin-top: -3px;
				color: $red;
			}		
		}
		.control {
			padding-left: 3px;
			padding-right: 3px;
			@include breakpoint(below-small) {
				padding: 3px 7px;
			}
		}
		.active {
			a {
				background: $red;
				color: #fff;
			}
		}
		.page {
			@include breakpoint(below-small) {
				display: none;
			}		
		}
		
	}
}

.sisea-paging {
	text-align: center;
	.sisea-page {
		display: inline-block;
		a {
			padding: 10px;
		}
	}
	.sisea-current-page {
		color: $red;
		padding: 10px;
	}
}
