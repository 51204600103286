.events-list-01 {
	@include clfix;
	> div:first-child {
		@include span(6 of 12);
		position: relative;
		@include breakpoint(below-small) {
			@include span(12);
		}
	}
	> div:last-child {
		@include span(6 of 12 last);
		@include breakpoint(below-small) {
			@include span(12);
			margin-top: 30px;
		}
	}
	&__text {
		background-color: #fff;
		padding: $content-padding;
		.event-data-list {
			margin-top: 30px;
		}
	}
	.heading {
		margin-bottom: 10px;
	}
	.sub {
		@extend %p-style;
	}
	.date-lable {
		position: absolute;
		left: 0;
		top: 0;
		padding: 15px;
		background-color: $main-color;
		color: #fff;
	}
}

.events-list {
	&__item {
		padding: $content-padding 0;
		border-top: 1px solid #ddd;
		position: relative;
		@include clfix;
		&:first-child {
			display: none;
		}
		&:nth-child(2) {
			padding-top: 0;
			border-top: 0;
		}
	}
	.col-1 {
		width: 110px;
		position: absolute;
	}
	.col-2{
		width: 100%;
		padding-left: 110px;
	}
	.heading {
		font-size: 1.3em;
		font-weight: $weight-b;
	}
	.event-data-list {
		> li {
			display: block;
		}
	}
}

.event-data-list {
	@extend %gray-style;
	> li {
		display: inline-block;
		margin: 5px;
		&:first-child {
			margin-right: 20px;
		}
	}
}

.style-cal {
	background-color: #fff;
	width: 85px;
	height: 85px;
	text-align: center;
	position: relative;
	&:before {
		content: "";
		background-color: $main-color;
		width: 40px;
		height: 3px;
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -20px;
	}
	.month {
		display: block;
		font-size: 35px;
		padding-top: 15px;
	}
	.date {
		color: #666;
		display: block;
		margin-top: 5px;
	}
}
