.form-group {
	@include clfix;
	margin-bottom: 20px;
	&__helf-item[type] {
		float: left;
		width: 48%;
		&:last-child {
			float: right;
		}
		@include breakpoint(below-small) {
			float: none;
			width: 100%;
			&:last-child {
				margin-top: 20px;
			}
		}
	}
}

.radio-heading {
	display: inline-block;
	margin-right: 50px;
	font-weight: normal;
}

.radio-group {
	display: inline-block;
	width: auto;
	margin-right: 20px;
	vertical-align: middle;
	label {
		cursor: pointer;
	}
}