.main-article,
.newest-article {
	background-color: #fff;
	padding: 34px;
	@include breakpoint(below-small) {
		padding: 24px;
	}
}

.col-8 {
	@include span(8);
	@include breakpoint(below-small) {
		@include span(12);
	}
}
.col-4 {
	@include span(4 last);
	@include breakpoint(below-small) {
		@include span(12);
		margin-top: 20px;
	}
}

.main-article__content {
	margin: 15px 0;
	img {
		display: block;
		margin: 15px auto;
		width: auto;
		max-width: 100%;
	}
	p {
		strong {
			font-weight: 600;
			color: #444;
		}
	}
	a {
		color: $blue;
		transition: color .3s;
		text-decoration: underline;
		&:hover {
			color: $blue + 30;
		}
	}
	ul {
		margin-left: 15px;
		> li {
			list-style: disc;
			@extend %p-style;
		}
	}
	ol {
		margin-left: 15px;
		> li {
			list-style: decimal;
			@extend %p-style;
		}
	}
	h3 {
		font-size: 1.8em;
		line-height: 1.3em;
		font-weight: 600;
		margin-top: 30px;
		margin-bottom: 10px;
	}
	h1, h2, h4, h5, h6 {
		font-size: 1em;
	}
}

.main-article {
	&__heading {
		color: $orange;
		padding-bottom: 15px;
		h1 {
			font-size: 2.25em;
		    font-weight: 700;
		    letter-spacing: 1px;
		    line-height: 1.3em;
		}
		@include breakpoint(below-mobile) {
			h1,h2 { font-size: 1.3em; }
		}
	}
	&__contact {
		margin-top: 40px;
	}
	.orange-hr {
		margin-bottom: 15px;
	}
	.date {
		@extend %gray-style;
		margin-bottom: 10px;
		@include breakpoint(below-small) {
			font-size: .9em;
		}
	}
	.author {
		@extend %gray-style;
		font-style: italic;
		font-size: .95em;
		letter-spacing: 0.05em;
		padding-bottom: 20px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 2em;
	}
	&__control {
		margin-top: 30px;
		@include clfix;
		i {
			margin-top: -5px;
			transition: transform .3s;
		}
		span {
			display: inline-block;
			padding: 0 10px;
		}
		.prev, .next {
			color: $red;
		}
		.prev {
			float: left;
			&:hover {
				i {
					transform: translateX(-10px);
				}
			}
		}
		.next {
			float: right;
			&:hover {
				i {
					transform: translateX(10px);
				}
			}
		}
	}
	.event-data-list {
		color: $red;
		font-size: 1.05em;
		margin-left: -5px;
	}
}

.newest-article {
	.heading {
		color: $red;
	}
	&__item {
		border-bottom: 1px solid #ccc;
		padding-bottom: 15px;
		margin-top: 20px;
		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}
		@include breakpoint(below-small) {
			@include clfix;
		}
		&:hover {
			figcaption {
				color: $orange;
			}
		}
		figcaption {
			margin-top: 10px;
			font-size: 1.05em;
			line-height: 1.3em;
			color: $gray;
			@include breakpoint(below-small) {
				float: left;
				width: 70%;
				margin-top: 0;
			}
			@include breakpoint(below-mobile) {
				width: 60%;
			}
		}
		img {
			@include breakpoint(below-small) {
				float: left;
				width: 20%;
				margin-right: 15px;
			}
			@include breakpoint(below-mobile) {
				width: 30%;
			}
		}
	}
}
